'use es6';

import createNextState, { enableES5, isDraft, current, isDraftable } from 'immer';
export { default as createNextState, current, freeze, isDraft, original } from 'immer';
import { combineReducers, applyMiddleware, createStore, compose } from 'redux';
export * from 'redux';
import { createSelector } from 'reselect';
export { createSelector } from 'reselect';
import thunkMiddleware from 'redux-thunk';
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var createDraftSafeSelector = (...args) => {
  const selector = createSelector(...args);
  const wrappedSelector = (value, ...rest) => selector(isDraft(value) ? current(value) : value, ...rest);
  return wrappedSelector;
};
var composeWithDevTools = typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : function () {
  if (arguments.length === 0) return void 0;
  if (typeof arguments[0] === "object") return compose;
  return compose.apply(null, arguments);
};
// src/isPlainObject.ts
function isPlainObject(value) {
  if (typeof value !== "object" || value === null) return false;
  let proto = Object.getPrototypeOf(value);
  if (proto === null) return true;
  let baseProto = proto;
  while (Object.getPrototypeOf(baseProto) !== null) {
    baseProto = Object.getPrototypeOf(baseProto);
  }
  return proto === baseProto;
}
// src/utils.ts
function getTimeMeasureUtils(maxDelay, fnName) {
  let elapsed = 0;
  return {
    measureTime(fn) {
      const started = Date.now();
      try {
        return fn();
      } finally {
        const finished = Date.now();
        elapsed += finished - started;
      }
    },
    warnIfExceeded() {
      if (elapsed > maxDelay) {
        console.warn(`${fnName} took ${elapsed}ms, which is more than the warning threshold of ${maxDelay}ms. 
If your state or actions are very large, you may want to disable the middleware as it might cause too much of a slowdown in development mode. See https://redux-toolkit.js.org/api/getDefaultMiddleware for instructions.
It is disabled in production builds, so you don't need to worry about that.`);
      }
    }
  };
}
var MiddlewareArray = class extends Array {
  constructor(...args) {
    super(...args);
    Object.setPrototypeOf(this, MiddlewareArray.prototype);
  }
  static get [Symbol.species]() {
    return MiddlewareArray;
  }
  concat(...arr) {
    return super.concat.apply(this, arr);
  }
  prepend(...arr) {
    if (arr.length === 1 && Array.isArray(arr[0])) {
      return new MiddlewareArray(...arr[0].concat(this));
    }
    return new MiddlewareArray(...arr.concat(this));
  }
};
// src/immutableStateInvariantMiddleware.ts
var isProduction = process.env.NODE_ENV === "production";
var prefix = "Invariant failed";
function invariant(condition, message) {
  if (condition) {
    return;
  }
  if (isProduction) {
    throw new Error(prefix);
  }
  throw new Error(`${prefix}: ${message || ""}`);
}
function stringify(obj, serializer, indent, decycler) {
  return JSON.stringify(obj, getSerialize(serializer, decycler), indent);
}
function getSerialize(serializer, decycler) {
  let stack = [],
    keys = [];
  if (!decycler) decycler = function (_, value) {
    if (stack[0] === value) return "[Circular ~]";
    return "[Circular ~." + keys.slice(0, stack.indexOf(value)).join(".") + "]";
  };
  return function (key, value) {
    if (stack.length > 0) {
      var thisPos = stack.indexOf(this);
      ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
      ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
      if (~stack.indexOf(value)) value = decycler.call(this, key, value);
    } else stack.push(value);
    return serializer == null ? value : serializer.call(this, key, value);
  };
}
function isImmutableDefault(value) {
  return typeof value !== "object" || value === null || typeof value === "undefined" || Object.isFrozen(value);
}
function trackForMutations(isImmutable, ignorePaths, obj) {
  const trackedProperties = trackProperties(isImmutable, ignorePaths, obj);
  return {
    detectMutations() {
      return detectMutations(isImmutable, ignorePaths, trackedProperties, obj);
    }
  };
}
function trackProperties(isImmutable, ignorePaths = [], obj, path = "") {
  const tracked = {
    value: obj
  };
  if (!isImmutable(obj)) {
    tracked.children = {};
    for (const key in obj) {
      const childPath = path ? path + "." + key : key;
      if (ignorePaths.length && ignorePaths.indexOf(childPath) !== -1) {
        continue;
      }
      tracked.children[key] = trackProperties(isImmutable, ignorePaths, obj[key], childPath);
    }
  }
  return tracked;
}
function detectMutations(isImmutable, ignorePaths = [], trackedProperty, obj, sameParentRef = false, path = "") {
  const prevObj = trackedProperty ? trackedProperty.value : void 0;
  const sameRef = prevObj === obj;
  if (sameParentRef && !sameRef && !Number.isNaN(obj)) {
    return {
      wasMutated: true,
      path
    };
  }
  if (isImmutable(prevObj) || isImmutable(obj)) {
    return {
      wasMutated: false
    };
  }
  const keysToDetect = {};
  for (let key in trackedProperty.children) {
    keysToDetect[key] = true;
  }
  for (let key in obj) {
    keysToDetect[key] = true;
  }
  for (let key in keysToDetect) {
    const childPath = path ? path + "." + key : key;
    if (ignorePaths.length && ignorePaths.indexOf(childPath) !== -1) {
      continue;
    }
    const result = detectMutations(isImmutable, ignorePaths, trackedProperty.children[key], obj[key], sameRef, childPath);
    if (result.wasMutated) {
      return result;
    }
  }
  return {
    wasMutated: false
  };
}
function createImmutableStateInvariantMiddleware(options = {}) {
  if (process.env.NODE_ENV === "production") {
    return () => next => action => next(action);
  }
  let {
    isImmutable = isImmutableDefault,
    ignoredPaths,
    warnAfter = 32,
    ignore
  } = options;
  ignoredPaths = ignoredPaths || ignore;
  const track = trackForMutations.bind(null, isImmutable, ignoredPaths);
  return ({
    getState
  }) => {
    let state = getState();
    let tracker = track(state);
    let result;
    return next => action => {
      const measureUtils = getTimeMeasureUtils(warnAfter, "ImmutableStateInvariantMiddleware");
      measureUtils.measureTime(() => {
        state = getState();
        result = tracker.detectMutations();
        tracker = track(state);
        invariant(!result.wasMutated, `A state mutation was detected between dispatches, in the path '${result.path || ""}'.  This may cause incorrect behavior. (https://redux.js.org/style-guide/style-guide#do-not-mutate-state)`);
      });
      const dispatchedAction = next(action);
      measureUtils.measureTime(() => {
        state = getState();
        result = tracker.detectMutations();
        tracker = track(state);
        result.wasMutated && invariant(!result.wasMutated, `A state mutation was detected inside a dispatch, in the path: ${result.path || ""}. Take a look at the reducer(s) handling the action ${stringify(action)}. (https://redux.js.org/style-guide/style-guide#do-not-mutate-state)`);
      });
      measureUtils.warnIfExceeded();
      return dispatchedAction;
    };
  };
}
// src/serializableStateInvariantMiddleware.ts
function isPlain(val) {
  const type = typeof val;
  return type === "undefined" || val === null || type === "string" || type === "boolean" || type === "number" || Array.isArray(val) || isPlainObject(val);
}
function findNonSerializableValue(value, path = "", isSerializable = isPlain, getEntries, ignoredPaths = []) {
  let foundNestedSerializable;
  if (!isSerializable(value)) {
    return {
      keyPath: path || "<root>",
      value
    };
  }
  if (typeof value !== "object" || value === null) {
    return false;
  }
  const entries = getEntries != null ? getEntries(value) : Object.entries(value);
  const hasIgnoredPaths = ignoredPaths.length > 0;
  for (const [key, nestedValue] of entries) {
    const nestedPath = path ? path + "." + key : key;
    if (hasIgnoredPaths && ignoredPaths.indexOf(nestedPath) >= 0) {
      continue;
    }
    if (!isSerializable(nestedValue)) {
      return {
        keyPath: nestedPath,
        value: nestedValue
      };
    }
    if (typeof nestedValue === "object") {
      foundNestedSerializable = findNonSerializableValue(nestedValue, nestedPath, isSerializable, getEntries, ignoredPaths);
      if (foundNestedSerializable) {
        return foundNestedSerializable;
      }
    }
  }
  return false;
}
function createSerializableStateInvariantMiddleware(options = {}) {
  if (process.env.NODE_ENV === "production") {
    return () => next => action => next(action);
  }
  const {
    isSerializable = isPlain,
    getEntries,
    ignoredActions = [],
    ignoredActionPaths = ["meta.arg", "meta.baseQueryMeta"],
    ignoredPaths = [],
    warnAfter = 32,
    ignoreState = false,
    ignoreActions = false
  } = options;
  return storeAPI => next => action => {
    const result = next(action);
    const measureUtils = getTimeMeasureUtils(warnAfter, "SerializableStateInvariantMiddleware");
    if (!ignoreActions && !(ignoredActions.length && ignoredActions.indexOf(action.type) !== -1)) {
      measureUtils.measureTime(() => {
        const foundActionNonSerializableValue = findNonSerializableValue(action, "", isSerializable, getEntries, ignoredActionPaths);
        if (foundActionNonSerializableValue) {
          const {
            keyPath,
            value
          } = foundActionNonSerializableValue;
          console.error(`A non-serializable value was detected in an action, in the path: \`${keyPath}\`. Value:`, value, "\nTake a look at the logic that dispatched this action: ", action, "\n(See https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants)", "\n(To allow non-serializable values see: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data)");
        }
      });
    }
    if (!ignoreState) {
      measureUtils.measureTime(() => {
        const state = storeAPI.getState();
        const foundStateNonSerializableValue = findNonSerializableValue(state, "", isSerializable, getEntries, ignoredPaths);
        if (foundStateNonSerializableValue) {
          const {
            keyPath,
            value
          } = foundStateNonSerializableValue;
          console.error(`A non-serializable value was detected in the state, in the path: \`${keyPath}\`. Value:`, value, `
Take a look at the reducer(s) handling this action type: ${action.type}.
(See https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state)`);
        }
      });
      measureUtils.warnIfExceeded();
    }
    return result;
  };
}
// src/getDefaultMiddleware.ts
function isBoolean(x) {
  return typeof x === "boolean";
}
function curryGetDefaultMiddleware() {
  return function curriedGetDefaultMiddleware(options) {
    return getDefaultMiddleware(options);
  };
}
function getDefaultMiddleware(options = {}) {
  const {
    thunk = true,
    immutableCheck = true,
    serializableCheck = true
  } = options;
  let middlewareArray = new MiddlewareArray();
  if (thunk) {
    if (isBoolean(thunk)) {
      middlewareArray.push(thunkMiddleware);
    } else {
      middlewareArray.push(thunkMiddleware.withExtraArgument(thunk.extraArgument));
    }
  }
  if (process.env.NODE_ENV !== "production") {
    if (immutableCheck) {
      let immutableOptions = {};
      if (!isBoolean(immutableCheck)) {
        immutableOptions = immutableCheck;
      }
      middlewareArray.unshift(createImmutableStateInvariantMiddleware(immutableOptions));
    }
    if (serializableCheck) {
      let serializableOptions = {};
      if (!isBoolean(serializableCheck)) {
        serializableOptions = serializableCheck;
      }
      middlewareArray.push(createSerializableStateInvariantMiddleware(serializableOptions));
    }
  }
  return middlewareArray;
}
// src/configureStore.ts
var IS_PRODUCTION = process.env.NODE_ENV === "production";
function configureStore(options) {
  const curriedGetDefaultMiddleware = curryGetDefaultMiddleware();
  const {
    reducer = void 0,
    middleware = curriedGetDefaultMiddleware(),
    devTools = true,
    preloadedState = void 0,
    enhancers = void 0
  } = options || {};
  let rootReducer;
  if (typeof reducer === "function") {
    rootReducer = reducer;
  } else if (isPlainObject(reducer)) {
    rootReducer = combineReducers(reducer);
  } else {
    throw new Error('"reducer" is a required argument, and must be a function or an object of functions that can be passed to combineReducers');
  }
  let finalMiddleware = middleware;
  if (typeof finalMiddleware === "function") {
    finalMiddleware = finalMiddleware(curriedGetDefaultMiddleware);
    if (!IS_PRODUCTION && !Array.isArray(finalMiddleware)) {
      throw new Error("when using a middleware builder function, an array of middleware must be returned");
    }
  }
  if (!IS_PRODUCTION && finalMiddleware.some(item => typeof item !== "function")) {
    throw new Error("each middleware provided to configureStore must be a function");
  }
  const middlewareEnhancer = applyMiddleware(...finalMiddleware);
  let finalCompose = compose;
  if (devTools) {
    finalCompose = composeWithDevTools(__spreadValues({
      trace: !IS_PRODUCTION
    }, typeof devTools === "object" && devTools));
  }
  let storeEnhancers = [middlewareEnhancer];
  if (Array.isArray(enhancers)) {
    storeEnhancers = [middlewareEnhancer, ...enhancers];
  } else if (typeof enhancers === "function") {
    storeEnhancers = enhancers(storeEnhancers);
  }
  const composedEnhancer = finalCompose(...storeEnhancers);
  return createStore(rootReducer, preloadedState, composedEnhancer);
}
// src/createAction.ts
function createAction(type, prepareAction) {
  function actionCreator(...args) {
    if (prepareAction) {
      let prepared = prepareAction(...args);
      if (!prepared) {
        throw new Error("prepareAction did not return an object");
      }
      return __spreadValues(__spreadValues({
        type,
        payload: prepared.payload
      }, "meta" in prepared && {
        meta: prepared.meta
      }), "error" in prepared && {
        error: prepared.error
      });
    }
    return {
      type,
      payload: args[0]
    };
  }
  actionCreator.toString = () => `${type}`;
  actionCreator.type = type;
  actionCreator.match = action => action.type === type;
  return actionCreator;
}
function isFSA(action) {
  return isPlainObject(action) && typeof action.type === "string" && Object.keys(action).every(isValidKey);
}
function isValidKey(key) {
  return ["type", "payload", "error", "meta"].indexOf(key) > -1;
}
function getType(actionCreator) {
  return `${actionCreator}`;
}
// src/mapBuilders.ts
function executeReducerBuilderCallback(builderCallback) {
  const actionsMap = {};
  const actionMatchers = [];
  let defaultCaseReducer;
  const builder = {
    addCase(typeOrActionCreator, reducer) {
      if (process.env.NODE_ENV !== "production") {
        if (actionMatchers.length > 0) {
          throw new Error("`builder.addCase` should only be called before calling `builder.addMatcher`");
        }
        if (defaultCaseReducer) {
          throw new Error("`builder.addCase` should only be called before calling `builder.addDefaultCase`");
        }
      }
      const type = typeof typeOrActionCreator === "string" ? typeOrActionCreator : typeOrActionCreator.type;
      if (type in actionsMap) {
        throw new Error("addCase cannot be called with two reducers for the same action type");
      }
      actionsMap[type] = reducer;
      return builder;
    },
    addMatcher(matcher, reducer) {
      if (process.env.NODE_ENV !== "production") {
        if (defaultCaseReducer) {
          throw new Error("`builder.addMatcher` should only be called before calling `builder.addDefaultCase`");
        }
      }
      actionMatchers.push({
        matcher,
        reducer
      });
      return builder;
    },
    addDefaultCase(reducer) {
      if (process.env.NODE_ENV !== "production") {
        if (defaultCaseReducer) {
          throw new Error("`builder.addDefaultCase` can only be called once");
        }
      }
      defaultCaseReducer = reducer;
      return builder;
    }
  };
  builderCallback(builder);
  return [actionsMap, actionMatchers, defaultCaseReducer];
}
// src/createReducer.ts
function isStateFunction(x) {
  return typeof x === "function";
}
function createReducer(initialState, mapOrBuilderCallback, actionMatchers = [], defaultCaseReducer) {
  let [actionsMap, finalActionMatchers, finalDefaultCaseReducer] = typeof mapOrBuilderCallback === "function" ? executeReducerBuilderCallback(mapOrBuilderCallback) : [mapOrBuilderCallback, actionMatchers, defaultCaseReducer];
  let getInitialState;
  if (isStateFunction(initialState)) {
    getInitialState = () => createNextState(initialState(), () => {});
  } else {
    const frozenInitialState = createNextState(initialState, () => {});
    getInitialState = () => frozenInitialState;
  }
  function reducer(state = getInitialState(), action) {
    let caseReducers = [actionsMap[action.type], ...finalActionMatchers.filter(({
      matcher
    }) => matcher(action)).map(({
      reducer: reducer2
    }) => reducer2)];
    if (caseReducers.filter(cr => !!cr).length === 0) {
      caseReducers = [finalDefaultCaseReducer];
    }
    return caseReducers.reduce((previousState, caseReducer) => {
      if (caseReducer) {
        if (isDraft(previousState)) {
          const draft = previousState;
          const result = caseReducer(draft, action);
          if (typeof result === "undefined") {
            return previousState;
          }
          return result;
        } else if (!isDraftable(previousState)) {
          const result = caseReducer(previousState, action);
          if (typeof result === "undefined") {
            if (previousState === null) {
              return previousState;
            }
            throw Error("A case reducer on a non-draftable value must not return undefined");
          }
          return result;
        } else {
          return createNextState(previousState, draft => {
            return caseReducer(draft, action);
          });
        }
      }
      return previousState;
    }, state);
  }
  reducer.getInitialState = getInitialState;
  return reducer;
}
// src/createSlice.ts
function getType2(slice, actionKey) {
  return `${slice}/${actionKey}`;
}
function createSlice(options) {
  const {
    name
  } = options;
  if (!name) {
    throw new Error("`name` is a required option for createSlice");
  }
  const initialState = typeof options.initialState == "function" ? options.initialState : createNextState(options.initialState, () => {});
  const reducers = options.reducers || {};
  const reducerNames = Object.keys(reducers);
  const sliceCaseReducersByName = {};
  const sliceCaseReducersByType = {};
  const actionCreators = {};
  reducerNames.forEach(reducerName => {
    const maybeReducerWithPrepare = reducers[reducerName];
    const type = getType2(name, reducerName);
    let caseReducer;
    let prepareCallback;
    if ("reducer" in maybeReducerWithPrepare) {
      caseReducer = maybeReducerWithPrepare.reducer;
      prepareCallback = maybeReducerWithPrepare.prepare;
    } else {
      caseReducer = maybeReducerWithPrepare;
    }
    sliceCaseReducersByName[reducerName] = caseReducer;
    sliceCaseReducersByType[type] = caseReducer;
    actionCreators[reducerName] = prepareCallback ? createAction(type, prepareCallback) : createAction(type);
  });
  function buildReducer() {
    const [extraReducers = {}, actionMatchers = [], defaultCaseReducer = void 0] = typeof options.extraReducers === "function" ? executeReducerBuilderCallback(options.extraReducers) : [options.extraReducers];
    const finalCaseReducers = __spreadValues(__spreadValues({}, extraReducers), sliceCaseReducersByType);
    return createReducer(initialState, finalCaseReducers, actionMatchers, defaultCaseReducer);
  }
  let _reducer;
  return {
    name,
    reducer(state, action) {
      if (!_reducer) _reducer = buildReducer();
      return _reducer(state, action);
    },
    actions: actionCreators,
    caseReducers: sliceCaseReducersByName,
    getInitialState() {
      if (!_reducer) _reducer = buildReducer();
      return _reducer.getInitialState();
    }
  };
}
// src/entities/entity_state.ts
function getInitialEntityState() {
  return {
    ids: [],
    entities: {}
  };
}
function createInitialStateFactory() {
  function getInitialState(additionalState = {}) {
    return Object.assign(getInitialEntityState(), additionalState);
  }
  return {
    getInitialState
  };
}
// src/entities/state_selectors.ts
function createSelectorsFactory() {
  function getSelectors(selectState) {
    const selectIds = state => state.ids;
    const selectEntities = state => state.entities;
    const selectAll = createDraftSafeSelector(selectIds, selectEntities, (ids, entities) => ids.map(id => entities[id]));
    const selectId = (_, id) => id;
    const selectById = (entities, id) => entities[id];
    const selectTotal = createDraftSafeSelector(selectIds, ids => ids.length);
    if (!selectState) {
      return {
        selectIds,
        selectEntities,
        selectAll,
        selectTotal,
        selectById: createDraftSafeSelector(selectEntities, selectId, selectById)
      };
    }
    const selectGlobalizedEntities = createDraftSafeSelector(selectState, selectEntities);
    return {
      selectIds: createDraftSafeSelector(selectState, selectIds),
      selectEntities: selectGlobalizedEntities,
      selectAll: createDraftSafeSelector(selectState, selectAll),
      selectTotal: createDraftSafeSelector(selectState, selectTotal),
      selectById: createDraftSafeSelector(selectGlobalizedEntities, selectId, selectById)
    };
  }
  return {
    getSelectors
  };
}
function createSingleArgumentStateOperator(mutator) {
  const operator = createStateOperator((_, state) => mutator(state));
  return function operation(state) {
    return operator(state, void 0);
  };
}
function createStateOperator(mutator) {
  return function operation(state, arg) {
    function isPayloadActionArgument(arg2) {
      return isFSA(arg2);
    }
    const runMutator = draft => {
      if (isPayloadActionArgument(arg)) {
        mutator(arg.payload, draft);
      } else {
        mutator(arg, draft);
      }
    };
    if (isDraft(state)) {
      runMutator(state);
      return state;
    } else {
      return createNextState(state, runMutator);
    }
  };
}
// src/entities/utils.ts
function selectIdValue(entity, selectId) {
  const key = selectId(entity);
  if (process.env.NODE_ENV !== "production" && key === void 0) {
    console.warn("The entity passed to the `selectId` implementation returned undefined.", "You should probably provide your own `selectId` implementation.", "The entity that was passed:", entity, "The `selectId` implementation:", selectId.toString());
  }
  return key;
}
function ensureEntitiesArray(entities) {
  if (!Array.isArray(entities)) {
    entities = Object.values(entities);
  }
  return entities;
}
function splitAddedUpdatedEntities(newEntities, selectId, state) {
  newEntities = ensureEntitiesArray(newEntities);
  const added = [];
  const updated = [];
  for (const entity of newEntities) {
    const id = selectIdValue(entity, selectId);
    if (id in state.entities) {
      updated.push({
        id,
        changes: entity
      });
    } else {
      added.push(entity);
    }
  }
  return [added, updated];
}
// src/entities/unsorted_state_adapter.ts
function createUnsortedStateAdapter(selectId) {
  function addOneMutably(entity, state) {
    const key = selectIdValue(entity, selectId);
    if (key in state.entities) {
      return;
    }
    state.ids.push(key);
    state.entities[key] = entity;
  }
  function addManyMutably(newEntities, state) {
    newEntities = ensureEntitiesArray(newEntities);
    for (const entity of newEntities) {
      addOneMutably(entity, state);
    }
  }
  function setOneMutably(entity, state) {
    const key = selectIdValue(entity, selectId);
    if (!(key in state.entities)) {
      state.ids.push(key);
    }
    state.entities[key] = entity;
  }
  function setManyMutably(newEntities, state) {
    newEntities = ensureEntitiesArray(newEntities);
    for (const entity of newEntities) {
      setOneMutably(entity, state);
    }
  }
  function setAllMutably(newEntities, state) {
    newEntities = ensureEntitiesArray(newEntities);
    state.ids = [];
    state.entities = {};
    addManyMutably(newEntities, state);
  }
  function removeOneMutably(key, state) {
    return removeManyMutably([key], state);
  }
  function removeManyMutably(keys, state) {
    let didMutate = false;
    keys.forEach(key => {
      if (key in state.entities) {
        delete state.entities[key];
        didMutate = true;
      }
    });
    if (didMutate) {
      state.ids = state.ids.filter(id => id in state.entities);
    }
  }
  function removeAllMutably(state) {
    Object.assign(state, {
      ids: [],
      entities: {}
    });
  }
  function takeNewKey(keys, update, state) {
    const original2 = state.entities[update.id];
    const updated = Object.assign({}, original2, update.changes);
    const newKey = selectIdValue(updated, selectId);
    const hasNewKey = newKey !== update.id;
    if (hasNewKey) {
      keys[update.id] = newKey;
      delete state.entities[update.id];
    }
    state.entities[newKey] = updated;
    return hasNewKey;
  }
  function updateOneMutably(update, state) {
    return updateManyMutably([update], state);
  }
  function updateManyMutably(updates, state) {
    const newKeys = {};
    const updatesPerEntity = {};
    updates.forEach(update => {
      if (update.id in state.entities) {
        updatesPerEntity[update.id] = {
          id: update.id,
          changes: __spreadValues(__spreadValues({}, updatesPerEntity[update.id] ? updatesPerEntity[update.id].changes : null), update.changes)
        };
      }
    });
    updates = Object.values(updatesPerEntity);
    const didMutateEntities = updates.length > 0;
    if (didMutateEntities) {
      const didMutateIds = updates.filter(update => takeNewKey(newKeys, update, state)).length > 0;
      if (didMutateIds) {
        state.ids = state.ids.map(id => newKeys[id] || id);
      }
    }
  }
  function upsertOneMutably(entity, state) {
    return upsertManyMutably([entity], state);
  }
  function upsertManyMutably(newEntities, state) {
    const [added, updated] = splitAddedUpdatedEntities(newEntities, selectId, state);
    updateManyMutably(updated, state);
    addManyMutably(added, state);
  }
  return {
    removeAll: createSingleArgumentStateOperator(removeAllMutably),
    addOne: createStateOperator(addOneMutably),
    addMany: createStateOperator(addManyMutably),
    setOne: createStateOperator(setOneMutably),
    setMany: createStateOperator(setManyMutably),
    setAll: createStateOperator(setAllMutably),
    updateOne: createStateOperator(updateOneMutably),
    updateMany: createStateOperator(updateManyMutably),
    upsertOne: createStateOperator(upsertOneMutably),
    upsertMany: createStateOperator(upsertManyMutably),
    removeOne: createStateOperator(removeOneMutably),
    removeMany: createStateOperator(removeManyMutably)
  };
}
// src/entities/sorted_state_adapter.ts
function createSortedStateAdapter(selectId, sort) {
  const {
    removeOne,
    removeMany,
    removeAll
  } = createUnsortedStateAdapter(selectId);
  function addOneMutably(entity, state) {
    return addManyMutably([entity], state);
  }
  function addManyMutably(newEntities, state) {
    newEntities = ensureEntitiesArray(newEntities);
    const models = newEntities.filter(model => !(selectIdValue(model, selectId) in state.entities));
    if (models.length !== 0) {
      merge(models, state);
    }
  }
  function setOneMutably(entity, state) {
    return setManyMutably([entity], state);
  }
  function setManyMutably(newEntities, state) {
    newEntities = ensureEntitiesArray(newEntities);
    if (newEntities.length !== 0) {
      merge(newEntities, state);
    }
  }
  function setAllMutably(newEntities, state) {
    newEntities = ensureEntitiesArray(newEntities);
    state.entities = {};
    state.ids = [];
    addManyMutably(newEntities, state);
  }
  function updateOneMutably(update, state) {
    return updateManyMutably([update], state);
  }
  function takeUpdatedModel(models, update, state) {
    if (!(update.id in state.entities)) {
      return false;
    }
    const original2 = state.entities[update.id];
    const updated = Object.assign({}, original2, update.changes);
    const newKey = selectIdValue(updated, selectId);
    delete state.entities[update.id];
    models.push(updated);
    return newKey !== update.id;
  }
  function updateManyMutably(updates, state) {
    const models = [];
    updates.forEach(update => takeUpdatedModel(models, update, state));
    if (models.length !== 0) {
      merge(models, state);
    }
  }
  function upsertOneMutably(entity, state) {
    return upsertManyMutably([entity], state);
  }
  function upsertManyMutably(newEntities, state) {
    const [added, updated] = splitAddedUpdatedEntities(newEntities, selectId, state);
    updateManyMutably(updated, state);
    addManyMutably(added, state);
  }
  function areArraysEqual(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length && i < b.length; i++) {
      if (a[i] === b[i]) {
        continue;
      }
      return false;
    }
    return true;
  }
  function merge(models, state) {
    models.forEach(model => {
      state.entities[selectId(model)] = model;
    });
    const allEntities = Object.values(state.entities);
    allEntities.sort(sort);
    const newSortedIds = allEntities.map(selectId);
    const {
      ids
    } = state;
    if (!areArraysEqual(ids, newSortedIds)) {
      state.ids = newSortedIds;
    }
  }
  return {
    removeOne,
    removeMany,
    removeAll,
    addOne: createStateOperator(addOneMutably),
    updateOne: createStateOperator(updateOneMutably),
    upsertOne: createStateOperator(upsertOneMutably),
    setOne: createStateOperator(setOneMutably),
    setMany: createStateOperator(setManyMutably),
    setAll: createStateOperator(setAllMutably),
    addMany: createStateOperator(addManyMutably),
    updateMany: createStateOperator(updateManyMutably),
    upsertMany: createStateOperator(upsertManyMutably)
  };
}
// src/entities/create_adapter.ts
function createEntityAdapter(options = {}) {
  const {
    selectId,
    sortComparer
  } = __spreadValues({
    sortComparer: false,
    selectId: instance => instance.id
  }, options);
  const stateFactory = createInitialStateFactory();
  const selectorsFactory = createSelectorsFactory();
  const stateAdapter = sortComparer ? createSortedStateAdapter(selectId, sortComparer) : createUnsortedStateAdapter(selectId);
  return __spreadValues(__spreadValues(__spreadValues({
    selectId,
    sortComparer
  }, stateFactory), selectorsFactory), stateAdapter);
}
// src/nanoid.ts
var urlAlphabet = "ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW";
var nanoid = (size = 21) => {
  let id = "";
  let i = size;
  while (i--) {
    id += urlAlphabet[Math.random() * 64 | 0];
  }
  return id;
};
// src/createAsyncThunk.ts
var commonProperties = ["name", "message", "stack", "code"];
var RejectWithValue = class {
  constructor(payload, meta) {
    this.payload = payload;
    this.meta = meta;
  }
};
var FulfillWithMeta = class {
  constructor(payload, meta) {
    this.payload = payload;
    this.meta = meta;
  }
};
var miniSerializeError = value => {
  if (typeof value === "object" && value !== null) {
    const simpleError = {};
    for (const property of commonProperties) {
      if (typeof value[property] === "string") {
        simpleError[property] = value[property];
      }
    }
    return simpleError;
  }
  return {
    message: String(value)
  };
};
function createAsyncThunk(typePrefix, payloadCreator, options) {
  const fulfilled = createAction(typePrefix + "/fulfilled", (payload, requestId, arg, meta) => ({
    payload,
    meta: __spreadProps(__spreadValues({}, meta || {}), {
      arg,
      requestId,
      requestStatus: "fulfilled"
    })
  }));
  const pending = createAction(typePrefix + "/pending", (requestId, arg, meta) => ({
    payload: void 0,
    meta: __spreadProps(__spreadValues({}, meta || {}), {
      arg,
      requestId,
      requestStatus: "pending"
    })
  }));
  const rejected = createAction(typePrefix + "/rejected", (error, requestId, arg, payload, meta) => ({
    payload,
    error: (options && options.serializeError || miniSerializeError)(error || "Rejected"),
    meta: __spreadProps(__spreadValues({}, meta || {}), {
      arg,
      requestId,
      rejectedWithValue: !!payload,
      requestStatus: "rejected",
      aborted: (error == null ? void 0 : error.name) === "AbortError",
      condition: (error == null ? void 0 : error.name) === "ConditionError"
    })
  }));
  let displayedWarning = false;
  const AC = typeof AbortController !== "undefined" ? AbortController : class {
    constructor() {
      this.signal = {
        aborted: false,
        addEventListener() {},
        dispatchEvent() {
          return false;
        },
        onabort() {},
        removeEventListener() {}
      };
    }
    abort() {
      if (process.env.NODE_ENV !== "production") {
        if (!displayedWarning) {
          displayedWarning = true;
          console.info(`This platform does not implement AbortController. 
If you want to use the AbortController to react to \`abort\` events, please consider importing a polyfill like 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'.`);
        }
      }
    }
  };
  function actionCreator(arg) {
    return (dispatch, getState, extra) => {
      const requestId = (options == null ? void 0 : options.idGenerator) ? options.idGenerator(arg) : nanoid();
      const abortController = new AC();
      let abortReason;
      const abortedPromise = new Promise((_, reject) => abortController.signal.addEventListener("abort", () => reject({
        name: "AbortError",
        message: abortReason || "Aborted"
      })));
      let started = false;
      function abort(reason) {
        if (started) {
          abortReason = reason;
          abortController.abort();
        }
      }
      const promise = async function () {
        var _a, _b;
        let finalAction;
        try {
          let conditionResult = (_a = options == null ? void 0 : options.condition) == null ? void 0 : _a.call(options, arg, {
            getState,
            extra
          });
          if (isThenable(conditionResult)) {
            conditionResult = await conditionResult;
          }
          if (conditionResult === false) {
            throw {
              name: "ConditionError",
              message: "Aborted due to condition callback returning false."
            };
          }
          started = true;
          dispatch(pending(requestId, arg, (_b = options == null ? void 0 : options.getPendingMeta) == null ? void 0 : _b.call(options, {
            requestId,
            arg
          }, {
            getState,
            extra
          })));
          finalAction = await Promise.race([abortedPromise, Promise.resolve(payloadCreator(arg, {
            dispatch,
            getState,
            extra,
            requestId,
            signal: abortController.signal,
            rejectWithValue: (value, meta) => {
              return new RejectWithValue(value, meta);
            },
            fulfillWithValue: (value, meta) => {
              return new FulfillWithMeta(value, meta);
            }
          })).then(result => {
            if (result instanceof RejectWithValue) {
              throw result;
            }
            if (result instanceof FulfillWithMeta) {
              return fulfilled(result.payload, requestId, arg, result.meta);
            }
            return fulfilled(result, requestId, arg);
          })]);
        } catch (err) {
          finalAction = err instanceof RejectWithValue ? rejected(null, requestId, arg, err.payload, err.meta) : rejected(err, requestId, arg);
        }
        const skipDispatch = options && !options.dispatchConditionRejection && rejected.match(finalAction) && finalAction.meta.condition;
        if (!skipDispatch) {
          dispatch(finalAction);
        }
        return finalAction;
      }();
      return Object.assign(promise, {
        abort,
        requestId,
        arg,
        unwrap() {
          return promise.then(unwrapResult);
        }
      });
    };
  }
  return Object.assign(actionCreator, {
    pending,
    rejected,
    fulfilled,
    typePrefix
  });
}
function unwrapResult(action) {
  if (action.meta && action.meta.rejectedWithValue) {
    throw action.payload;
  }
  if (action.error) {
    throw action.error;
  }
  return action.payload;
}
function isThenable(value) {
  return value !== null && typeof value === "object" && typeof value.then === "function";
}
// src/tsHelpers.ts
var hasMatchFunction = v => {
  return v && typeof v.match === "function";
};
// src/matchers.ts
var matches = (matcher, action) => {
  if (hasMatchFunction(matcher)) {
    return matcher.match(action);
  } else {
    return matcher(action);
  }
};
function isAnyOf(...matchers) {
  return action => {
    return matchers.some(matcher => matches(matcher, action));
  };
}
function isAllOf(...matchers) {
  return action => {
    return matchers.every(matcher => matches(matcher, action));
  };
}
function hasExpectedRequestMetadata(action, validStatus) {
  if (!action || !action.meta) return false;
  const hasValidRequestId = typeof action.meta.requestId === "string";
  const hasValidRequestStatus = validStatus.indexOf(action.meta.requestStatus) > -1;
  return hasValidRequestId && hasValidRequestStatus;
}
function isAsyncThunkArray(a) {
  return typeof a[0] === "function" && "pending" in a[0] && "fulfilled" in a[0] && "rejected" in a[0];
}
function isPending(...asyncThunks) {
  if (asyncThunks.length === 0) {
    return action => hasExpectedRequestMetadata(action, ["pending"]);
  }
  if (!isAsyncThunkArray(asyncThunks)) {
    return isPending()(asyncThunks[0]);
  }
  return action => {
    const matchers = asyncThunks.map(asyncThunk => asyncThunk.pending);
    const combinedMatcher = isAnyOf(...matchers);
    return combinedMatcher(action);
  };
}
function isRejected(...asyncThunks) {
  if (asyncThunks.length === 0) {
    return action => hasExpectedRequestMetadata(action, ["rejected"]);
  }
  if (!isAsyncThunkArray(asyncThunks)) {
    return isRejected()(asyncThunks[0]);
  }
  return action => {
    const matchers = asyncThunks.map(asyncThunk => asyncThunk.rejected);
    const combinedMatcher = isAnyOf(...matchers);
    return combinedMatcher(action);
  };
}
function isRejectedWithValue(...asyncThunks) {
  const hasFlag = action => {
    return action && action.meta && action.meta.rejectedWithValue;
  };
  if (asyncThunks.length === 0) {
    return action => {
      const combinedMatcher = isAllOf(isRejected(...asyncThunks), hasFlag);
      return combinedMatcher(action);
    };
  }
  if (!isAsyncThunkArray(asyncThunks)) {
    return isRejectedWithValue()(asyncThunks[0]);
  }
  return action => {
    const combinedMatcher = isAllOf(isRejected(...asyncThunks), hasFlag);
    return combinedMatcher(action);
  };
}
function isFulfilled(...asyncThunks) {
  if (asyncThunks.length === 0) {
    return action => hasExpectedRequestMetadata(action, ["fulfilled"]);
  }
  if (!isAsyncThunkArray(asyncThunks)) {
    return isFulfilled()(asyncThunks[0]);
  }
  return action => {
    const matchers = asyncThunks.map(asyncThunk => asyncThunk.fulfilled);
    const combinedMatcher = isAnyOf(...matchers);
    return combinedMatcher(action);
  };
}
function isAsyncThunkAction(...asyncThunks) {
  if (asyncThunks.length === 0) {
    return action => hasExpectedRequestMetadata(action, ["pending", "fulfilled", "rejected"]);
  }
  if (!isAsyncThunkArray(asyncThunks)) {
    return isAsyncThunkAction()(asyncThunks[0]);
  }
  return action => {
    const matchers = [];
    for (const asyncThunk of asyncThunks) {
      matchers.push(asyncThunk.pending, asyncThunk.rejected, asyncThunk.fulfilled);
    }
    const combinedMatcher = isAnyOf(...matchers);
    return combinedMatcher(action);
  };
}
// src/listenerMiddleware/utils.ts
var assertFunction = (func, expected) => {
  if (typeof func !== "function") {
    throw new TypeError(`${expected} is not a function`);
  }
};
var noop = () => {};
var catchRejection = (promise, onError = noop) => {
  promise.catch(onError);
  return promise;
};
var addAbortSignalListener = (abortSignal, callback) => {
  abortSignal.addEventListener("abort", callback, {
    once: true
  });
};
var abortControllerWithReason = (abortController, reason) => {
  const signal = abortController.signal;
  if (signal.aborted) {
    return;
  }
  if (!("reason" in signal)) {
    Object.defineProperty(signal, "reason", {
      enumerable: true,
      value: reason,
      configurable: true,
      writable: true
    });
  }
  abortController.abort(reason);
};
// src/listenerMiddleware/exceptions.ts
var task = "task";
var listener = "listener";
var completed = "completed";
var cancelled = "cancelled";
var taskCancelled = `task-${cancelled}`;
var taskCompleted = `task-${completed}`;
var listenerCancelled = `${listener}-${cancelled}`;
var listenerCompleted = `${listener}-${completed}`;
var TaskAbortError = class {
  constructor(code) {
    this.code = code;
    this.name = "TaskAbortError";
    this.message = `${task} ${cancelled} (reason: ${code})`;
  }
};
// src/listenerMiddleware/task.ts
var validateActive = signal => {
  if (signal.aborted) {
    throw new TaskAbortError(signal.reason);
  }
};
var promisifyAbortSignal = signal => {
  return catchRejection(new Promise((_, reject) => {
    const notifyRejection = () => reject(new TaskAbortError(signal.reason));
    if (signal.aborted) {
      notifyRejection();
    } else {
      addAbortSignalListener(signal, notifyRejection);
    }
  }));
};
var runTask = async (task2, cleanUp) => {
  try {
    await Promise.resolve();
    const value = await task2();
    return {
      status: "ok",
      value
    };
  } catch (error) {
    return {
      status: error instanceof TaskAbortError ? "cancelled" : "rejected",
      error
    };
  } finally {
    cleanUp == null ? void 0 : cleanUp();
  }
};
var createPause = signal => {
  return promise => {
    return catchRejection(Promise.race([promisifyAbortSignal(signal), promise]).then(output => {
      validateActive(signal);
      return output;
    }));
  };
};
var createDelay = signal => {
  const pause = createPause(signal);
  return timeoutMs => {
    return pause(new Promise(resolve => setTimeout(resolve, timeoutMs)));
  };
};
// src/listenerMiddleware/index.ts
var {
  assign
} = Object;
var INTERNAL_NIL_TOKEN = {};
var alm = "listenerMiddleware";
var createFork = parentAbortSignal => {
  const linkControllers = controller => addAbortSignalListener(parentAbortSignal, () => abortControllerWithReason(controller, parentAbortSignal.reason));
  return taskExecutor => {
    assertFunction(taskExecutor, "taskExecutor");
    const childAbortController = new AbortController();
    linkControllers(childAbortController);
    const result = runTask(async () => {
      validateActive(parentAbortSignal);
      validateActive(childAbortController.signal);
      const result2 = await taskExecutor({
        pause: createPause(childAbortController.signal),
        delay: createDelay(childAbortController.signal),
        signal: childAbortController.signal
      });
      validateActive(childAbortController.signal);
      return result2;
    }, () => abortControllerWithReason(childAbortController, taskCompleted));
    return {
      result: createPause(parentAbortSignal)(result),
      cancel() {
        abortControllerWithReason(childAbortController, taskCancelled);
      }
    };
  };
};
var createTakePattern = (startListening, signal) => {
  const take = async (predicate, timeout) => {
    validateActive(signal);
    let unsubscribe = () => {};
    const tuplePromise = new Promise(resolve => {
      unsubscribe = startListening({
        predicate,
        effect: (action, listenerApi) => {
          listenerApi.unsubscribe();
          resolve([action, listenerApi.getState(), listenerApi.getOriginalState()]);
        }
      });
    });
    const promises = [promisifyAbortSignal(signal), tuplePromise];
    if (timeout != null) {
      promises.push(new Promise(resolve => setTimeout(resolve, timeout, null)));
    }
    try {
      const output = await Promise.race(promises);
      validateActive(signal);
      return output;
    } finally {
      unsubscribe();
    }
  };
  return (predicate, timeout) => catchRejection(take(predicate, timeout));
};
var getListenerEntryPropsFrom = options => {
  let {
    type,
    actionCreator,
    matcher,
    predicate,
    effect
  } = options;
  if (type) {
    predicate = createAction(type).match;
  } else if (actionCreator) {
    type = actionCreator.type;
    predicate = actionCreator.match;
  } else if (matcher) {
    predicate = matcher;
  } else if (predicate) ;else {
    throw new Error("Creating or removing a listener requires one of the known fields for matching an action");
  }
  assertFunction(effect, "options.listener");
  return {
    predicate,
    type,
    effect
  };
};
var createListenerEntry = options => {
  const {
    type,
    predicate,
    effect
  } = getListenerEntryPropsFrom(options);
  const id = nanoid();
  const entry = {
    id,
    effect,
    type,
    predicate,
    pending: new Set(),
    unsubscribe: () => {
      throw new Error("Unsubscribe not initialized");
    }
  };
  return entry;
};
var createClearListenerMiddleware = listenerMap => {
  return () => {
    listenerMap.forEach(cancelActiveListeners);
    listenerMap.clear();
  };
};
var safelyNotifyError = (errorHandler, errorToNotify, errorInfo) => {
  try {
    errorHandler(errorToNotify, errorInfo);
  } catch (errorHandlerError) {
    setTimeout(() => {
      throw errorHandlerError;
    }, 0);
  }
};
var addListener = createAction(`${alm}/add`);
var clearAllListeners = createAction(`${alm}/removeAll`);
var removeListener = createAction(`${alm}/remove`);
var defaultErrorHandler = (...args) => {
  console.error(`${alm}/error`, ...args);
};
var cancelActiveListeners = entry => {
  entry.pending.forEach(controller => {
    abortControllerWithReason(controller, listenerCancelled);
  });
};
function createListenerMiddleware(middlewareOptions = {}) {
  const listenerMap = new Map();
  const {
    extra,
    onError = defaultErrorHandler
  } = middlewareOptions;
  assertFunction(onError, "onError");
  const insertEntry = entry => {
    entry.unsubscribe = () => listenerMap.delete(entry.id);
    listenerMap.set(entry.id, entry);
    return cancelOptions => {
      entry.unsubscribe();
      if (cancelOptions == null ? void 0 : cancelOptions.cancelActive) {
        cancelActiveListeners(entry);
      }
    };
  };
  const findListenerEntry = comparator => {
    for (const entry of listenerMap.values()) {
      if (comparator(entry)) {
        return entry;
      }
    }
    return void 0;
  };
  const startListening = options => {
    let entry = findListenerEntry(existingEntry => existingEntry.effect === options.effect);
    if (!entry) {
      entry = createListenerEntry(options);
    }
    return insertEntry(entry);
  };
  const stopListening = options => {
    const {
      type,
      effect,
      predicate
    } = getListenerEntryPropsFrom(options);
    const entry = findListenerEntry(entry2 => {
      const matchPredicateOrType = typeof type === "string" ? entry2.type === type : entry2.predicate === predicate;
      return matchPredicateOrType && entry2.effect === effect;
    });
    if (entry) {
      entry.unsubscribe();
      if (options.cancelActive) {
        cancelActiveListeners(entry);
      }
    }
    return !!entry;
  };
  const notifyListener = async (entry, action, api, getOriginalState) => {
    const internalTaskController = new AbortController();
    const take = createTakePattern(startListening, internalTaskController.signal);
    try {
      entry.pending.add(internalTaskController);
      await Promise.resolve(entry.effect(action, assign({}, api, {
        getOriginalState,
        condition: (predicate, timeout) => take(predicate, timeout).then(Boolean),
        take,
        delay: createDelay(internalTaskController.signal),
        pause: createPause(internalTaskController.signal),
        extra,
        signal: internalTaskController.signal,
        fork: createFork(internalTaskController.signal),
        unsubscribe: entry.unsubscribe,
        subscribe: () => {
          listenerMap.set(entry.id, entry);
        },
        cancelActiveListeners: () => {
          entry.pending.forEach((controller, _, set) => {
            if (controller !== internalTaskController) {
              abortControllerWithReason(controller, listenerCancelled);
              set.delete(controller);
            }
          });
        }
      })));
    } catch (listenerError) {
      if (!(listenerError instanceof TaskAbortError)) {
        safelyNotifyError(onError, listenerError, {
          raisedBy: "effect"
        });
      }
    } finally {
      abortControllerWithReason(internalTaskController, listenerCompleted);
      entry.pending.delete(internalTaskController);
    }
  };
  const clearListenerMiddleware = createClearListenerMiddleware(listenerMap);
  const middleware = api => next => action => {
    if (addListener.match(action)) {
      return startListening(action.payload);
    }
    if (clearAllListeners.match(action)) {
      clearListenerMiddleware();
      return;
    }
    if (removeListener.match(action)) {
      return stopListening(action.payload);
    }
    let originalState = api.getState();
    const getOriginalState = () => {
      if (originalState === INTERNAL_NIL_TOKEN) {
        throw new Error(`${alm}: getOriginalState can only be called synchronously`);
      }
      return originalState;
    };
    let result;
    try {
      result = next(action);
      if (listenerMap.size > 0) {
        let currentState = api.getState();
        const listenerEntries = Array.from(listenerMap.values());
        for (let entry of listenerEntries) {
          let runListener = false;
          try {
            runListener = entry.predicate(action, currentState, originalState);
          } catch (predicateError) {
            runListener = false;
            safelyNotifyError(onError, predicateError, {
              raisedBy: "predicate"
            });
          }
          if (!runListener) {
            continue;
          }
          notifyListener(entry, action, api, getOriginalState);
        }
      }
    } finally {
      originalState = INTERNAL_NIL_TOKEN;
    }
    return result;
  };
  return {
    middleware,
    startListening,
    stopListening,
    clearListeners: clearListenerMiddleware
  };
}
// src/index.ts
enableES5();
export { MiddlewareArray, TaskAbortError, addListener, clearAllListeners, configureStore, createAction, createAsyncThunk, createDraftSafeSelector, createEntityAdapter, createImmutableStateInvariantMiddleware, createListenerMiddleware, createReducer, createSerializableStateInvariantMiddleware, createSlice, findNonSerializableValue, getDefaultMiddleware, getType, isAllOf, isAnyOf, isAsyncThunkAction, isFulfilled, isImmutableDefault, isPending, isPlain, isPlainObject, isRejected, isRejectedWithValue, miniSerializeError, nanoid, removeListener, unwrapResult };