import { useCallback, useState } from 'react';
export default function useFocused({
  focused,
  onFocus,
  onBlur
}) {
  const [focusedState, setFocusedState] = useState(false);
  const handleFocus = useCallback(evt => {
    if (onFocus) onFocus(evt);
    setFocusedState(true);
  }, [onFocus]);
  const handleBlur = useCallback(evt => {
    if (onBlur) onBlur(evt);
    setFocusedState(false);
  }, [onBlur]);
  return {
    focused: focused != null ? focused : focusedState,
    onFocus: focusedState ? onFocus : handleFocus,
    onBlur: focusedState ? handleBlur : onBlur
  };
}