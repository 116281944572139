'use es6';

// from hub-http/helpers/params to avoid dep
export const parse = query => {
  if (query == null || query.trim() === '') {
    return {};
  }
  if (query.indexOf('?') === 0) {
    query = query.substring(1);
  }
  return query.split('&').reduce((result, current) => {
    const [key, value] = current.split('=');
    let keyName = key;
    const decodedValue = decodeURIComponent(value);

    // parse query strings in the form foo[] for arrays. This is only for compatibility and
    // repeating keys should be favored.
    if (keyName.length > 2 && keyName.lastIndexOf('[]') === keyName.length - 2) {
      keyName = keyName.substring(0, keyName.length - 2);
    }
    const existing = result[keyName];
    if (existing !== undefined) {
      if (Array.isArray(existing)) {
        existing.push(decodedValue);
      } else {
        result[keyName] = [existing, decodedValue];
      }
    } else {
      result[keyName] = decodedValue;
    }
    return result;
  }, {});
};