'use es6';

import I18n from 'I18n';
import formatPhoneNumber from './formatPhoneNumber';
export default ((value, extension) => {
  const phoneNumber = formatPhoneNumber(value);
  return I18n.text('i18n.numberRepresentation.phoneNumberWithExtension', {
    phoneNumber,
    extension
  });
});