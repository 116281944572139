import { combineReducers } from 'redux';
// @ts-expect-error Untyped module
import Notifications from 'ReduxMessenger/reducers/Notifications';
import buyerOverridesReducer from '../state/BuyerOverrides/reducer';
import discountCodeSlice from '../state/DiscountCode/discountCodeSlice';
import { configureStore } from '@reduxjs/toolkit';
const reducer = combineReducers({
  notifications: Notifications,
  buyerOverrides: buyerOverridesReducer,
  discountCode: discountCodeSlice
});
export const createStore = ({
  preloadedState
} = {}) => configureStore({
  devTools: true,
  reducer,
  preloadedState
});
export default reducer;