import { useEffect } from 'react';
// @ts-expect-error untyped module
import FireAlarmApp from 'FireAlarmUi/js/FireAlarmApp';

/*
Runs an effect that fetches active fire alarms for `payments-ui-app` and mounts
any active fire alarms as a child of #fire-alarm-container

See https://git.hubteam.com/HubSpot/FireAlarmUi for using fire alarms on apps
without navigation

See https://git.hubteam.com/HubSpot/quotes-ui/blob/master/quotes-ui-components/static/js/effects/fireAlarmEffects.js
for another example usage
*/
export function useFireAlarm(containerId) {
  useEffect(() => {
    const config = {
      appName: 'payments-ui-app',
      containers: {
        default: {
          parent: document.getElementById(containerId)
        }
      }
    };
    const fireAlarm = new FireAlarmApp(window.hubspot, config);
    window.hubspot[FireAlarmApp.HUBSPOT_FIREALARM_KEY] = fireAlarm;
    fireAlarm.start();
  }, [containerId]);
}