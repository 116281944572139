import { FUTURE_START_DATE, RELATIVE_START_DATE } from '../constants/LineItems';
import I18n from 'I18n';
import { isSome } from '../types/utils/helpers';
import { getLineItemRecurringFrequencyBySubtotal } from './recurring';
import { fromJS } from 'immutable';
export const getIntervalDescription = (currencyCode, recurringSubtotals) => {
  let intervalDescription = '';
  recurringSubtotals.map(recurringSubtotal => {
    const subtotal = recurringSubtotal && fromJS(recurringSubtotal).get('subtotal');
    const recurringBillingTerms = recurringSubtotal && fromJS(recurringSubtotal).get('recurringBillingTerms');
    const numPayments = recurringBillingTerms.get('numPayments');
    const initialPayment = recurringBillingTerms.get('initialPayment');
    const intervalUnit = recurringBillingTerms.get('intervalUnit');
    const intervalLength = recurringBillingTerms.get('intervalLength');
    const initialPaymentType = initialPayment.get('type');
    const futureStartDate = initialPayment.get('futureStartDate');
    const relativeDelayLength = initialPayment.getIn(['relativeDelay', 'delayLength']);
    const relativeDelayUnit = initialPayment.getIn(['relativeDelay', 'delayUnit']);
    const relativeDelayString = relativeDelayUnit && I18n.text(`payments.intervalDescription.rsd.${relativeDelayUnit}`, {
      count: relativeDelayLength
    });
    const formattedFutureStartDate = futureStartDate && I18n.moment.utc(Number(futureStartDate)).format('LL');
    const formattedDateToday = I18n.moment.utc(Date.now()).format('LL');
    const formattedSubtotal = I18n.formatCurrency(subtotal, {
      currencyCode
    });
    const paymentCadence = getLineItemRecurringFrequencyBySubtotal(intervalUnit, intervalLength);
    const paymentString = I18n.text(`payments.intervalDescription.paymentCadence.${paymentCadence}`, {
      subtotal: formattedSubtotal
    });
    const getMessageTranslationKey = () => {
      if (initialPaymentType === FUTURE_START_DATE) {
        return isSome(numPayments) ? 'payments.intervalDescription.fsd.fixedPayments' : 'payments.intervalDescription.fsd.indefinitePayments';
      } else if (initialPaymentType === RELATIVE_START_DATE) {
        return isSome(numPayments) ? 'payments.intervalDescription.rsd.fixedPayments' : 'payments.intervalDescription.rsd.indefinitePayments';
      } else {
        return isSome(numPayments) ? 'payments.intervalDescription.checkout.fixedPayments' : 'payments.intervalDescription.checkout.indefinitePayments';
      }
    };
    intervalDescription += I18n.text(getMessageTranslationKey(), {
      payment: paymentString,
      futureStartDate: formattedFutureStartDate,
      count: numPayments,
      relativeDelay: relativeDelayString,
      dateToday: formattedDateToday
    });
    return intervalDescription;
  });
  // removing the last '.' because Stripe already provides one in the PADs modal
  intervalDescription = intervalDescription.substring(0, intervalDescription.length - 2);
  return intervalDescription;
};