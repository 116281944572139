import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider';
import lang from 'i2l?query=sporks!../../lang/en.lyaml';
import registerAllData from 'I18n/init/register/sync/registerAllData';
import * as localeUtils from './locale'; // re-import utils to allow mocking in tests

const DEFAULT_LANGUAGE = 'en';
const ES_US_LOCALE = 'es-US';
const ES_MX_LOCALE = 'es-MX';
// See https://issues.hubspotcentral.com/browse/PAYSUP-814 for why we have to account for es-419
const ES_LATAM_LOCALE = 'es-419';

// Copied from https://git.hubteam.com/HubSpot/I18n/blob/4bc9ae0ed020b4a155887adc48d4a414bc097729/static/js/init/providers/createPublicI18nProvider.ts#L21
export const getBrowserLocale = () => {
  if (navigator && navigator.languages && navigator.languages[0]) {
    return navigator.languages[0];
  } else if (navigator && navigator.language) {
    return navigator.language;
  }
  return DEFAULT_LANGUAGE;
};

// Overrides certain locale values since they're not supported by our i18n setup
// Full context here: https://hubspot.slack.com/archives/C6PL2HX0C/p1699986304019579
export const setAppLocale = I18nPublicProvider => {
  let locale = localeUtils.getBrowserLocale();
  if (locale === ES_US_LOCALE || locale === ES_LATAM_LOCALE) {
    locale = ES_MX_LOCALE;
  }
  I18nPublicProvider.setLocale({
    locale,
    langEnabled: true
  });
};
export const getLangPromise = () => {
  const I18nPublicProvider = createPublicI18nProvider({
    manuallySetLocale: true
  });
  const langAvailable = Promise.all([I18nPublicProvider.register(lang), registerAllData(I18nPublicProvider)]);
  localeUtils.setAppLocale(I18nPublicProvider);
  return langAvailable;
};