export const INTERACTION_SECTIONS = {
  contactInfo: 'contactInfo',
  shippingAddress: 'shippingAddress',
  billingAddress: 'billingAddress',
  fullBillingAddress: 'fullBillingAddress',
  cardInfo: 'cardInfo',
  bankInfo: 'bankInfo',
  paymentInfo: 'paymentInfo',
  orderSummaryLineItem: 'orderSummaryLineItem'
};
export const INTERACTION_TARGETS = {
  accountHolderName: 'accountHolderName',
  accountHolderType: 'accountHolderType',
  accountNumber: 'accountNumber',
  address: 'address',
  address2: 'address2',
  billingSameAsShipping: 'billingSameAsShipping',
  cardNumber: 'cardNumber',
  city: 'city',
  confirmAccountNumber: 'confirmAccountNumber',
  country: 'country',
  cvc: 'cvc',
  email: 'email',
  expirationDate: 'expirationDate',
  firstname: 'firstname',
  firstName: 'firstName',
  fullName: 'fullName',
  lastname: 'lastname',
  lastName: 'lastName',
  paymentMethod: 'paymentMethod',
  phone: 'phone',
  postalCode: 'postalCode',
  routingNumber: 'routingNumber',
  state: 'state',
  optionalItemAddButton: 'optionalItemAddButton'
};