module.exports = {
  "/:publicId": {
    "name": "index-redirect",
    "success": {
      "not found": [
        "NOT_FOUND"
      ],
      "loaded": [
        "INDEX_LOADED"
      ]
    }
  },
  "/purchase/:paymentSessionId": {
    "name": "payment-session",
    "success": {
      "not found": [
        "NOT_FOUND"
      ],
      "loaded": [
        "INDEX_LOADED"
      ]
    },
    "error": [
      "RHUMB_GLOBAL_ERROR"
    ]
  },
  "/iframe/*": {
    "name": "iframe-preview",
    "success": {
      "not found": [
        "NOT_FOUND"
      ],
      "loaded": [
        "INDEX_LOADED"
      ]
    },
    "error": [
      "RHUMB_GLOBAL_ERROR"
    ]
  }
};