module.exports = {
  "interactionEvent": {
    "name": "interaction event",
    "class": "interaction",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "usageEvent": {
    "name": "usage event",
    "class": "usage",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "subscriptionChange": {
    "name": "subscription change",
    "class": "usage",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string"
      },
      "action": {
        "type": "string"
      },
      "delta": {
        "type": "number",
        "isOptional": true
      },
      "dateRange": {
        "type": "number",
        "isOptional": true
      },
      "updateType": {
        "type": [
          "UPGRADE",
          "DOWNGRADE",
          ""
        ],
        "isOptional": true
      },
      "discountsApplied": {
        "type": "boolean",
        "isOptional": true
      },
      "added": {
        "type": "boolean",
        "isOptional": true
      },
      "swapped": {
        "type": "boolean",
        "isOptional": true
      },
      "removed": {
        "type": "boolean",
        "isOptional": true
      },
      "proratedAdjustment": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "subscriptionUpsert": {
    "name": "subscription upsert",
    "class": "usage",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "createdAt": {
        "type": "number"
      },
      "termType": {
        "type": [
          "DAYS",
          "WEEKS",
          "TWO_WEEKS",
          "MONTHS",
          "QUARTERS",
          "SIX_MONTHS",
          "YEARS"
        ]
      },
      "numPayments": {
        "type": "number"
      },
      "startDate": {
        "type": "number",
        "isOptional": true
      },
      "delay": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "subscriptionDeleted": {
    "name": "subscription deleted",
    "class": "usage",
    "properties": {
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  }
};