import { createSelector } from 'reselect';
const getBuyerOverrides = state => state.buyerOverrides;
const getId = (state, id) => id;
export const getBuyerOverridesIsLoading = createSelector([getBuyerOverrides], buyerOverrides => buyerOverrides.isLoading);
export const getBuyerOverridesQuantity = createSelector([getBuyerOverrides, getId], (buyerOverrides, id) => buyerOverrides.quantities[id]);
export const getBuyerOverridesSelections = createSelector([getBuyerOverrides], buyerOverrides => buyerOverrides.selections);
export const getBuyerOverridesPartialPaymentAmount = createSelector([getBuyerOverrides], buyerOverrides => buyerOverrides.partialPaymentAmount);
export const getBuyerOverridesLineItems = createSelector([getBuyerOverrides], ({
  selections,
  prices,
  quantities
}) => {
  const allOverrideKeys = Array.from(new Set([selections, prices, quantities].flatMap(index => Object.keys(index))));
  return allOverrideKeys.reduce((acc, id) => {
    // don't return actively deselected items.  Do return items that are undefined and selected
    if (selections[id] === false) return acc;
    const override = acc[acc.length] = {
      id: Number(id)
    };
    if (id in quantities)
      // @ts-expect-error ts doesn't like this mutation, but it's safe.
      override.quantity = quantities[id];else if (id in prices)
      // @ts-expect-error ts doesn't like this mutation, but it's safe.
      override.price = prices[id];
    return acc;
  }, []);
});
export const getExpiredFutureStartDateLineItemIds = createSelector([getBuyerOverrides], buyerOverrides => buyerOverrides.expiredFutureStartDateLineItemIds);