import { useQuery } from 'data-fetching-client';
import { USD } from 'self-service-api/constants/CurrencyCodes';
import { useIsIframePreviewTestMode } from '../components/TestMode/useIsIframePreviewTestMode';
import { usePaymentFormContext } from '../context/PaymentFormContext';
import { GET_TRANSACTION_LIMITS } from '../data/queries';
const getStubLimit = () => {
  return {
    currencyCode: USD,
    processorType: 'HS_PAYMENTS',
    maximumSubscriptions: 1,
    minimumAmountForPaymentMethod: {
      CARD: 0.5,
      ACH: 0.5,
      BACS: 0.5,
      PADS: 0.5,
      SEPA: 0.5
    },
    maximumAmountForPaymentMethod: {
      CARD: 999999.99,
      ACH: 100000,
      BACS: 100000,
      PADS: 999999.99,
      SEPA: 10000
    }
  };
};
export function useTransactionLimits(portalId, currencyCode) {
  const {
    checkoutSession: checkoutSessionMap
  } = usePaymentFormContext();
  const checkoutSession = checkoutSessionMap.toJS();
  const {
    isIframePreviewTestMode
  } = useIsIframePreviewTestMode();
  const {
    data,
    loading
  } = useQuery(GET_TRANSACTION_LIMITS, {
    skip: isIframePreviewTestMode,
    variables: {
      portalId: checkoutSession.portalId || portalId,
      currency: checkoutSession.currencyCode || currencyCode
    }
  });
  const limits = data && data.limits[0];
  const transactionLimitsResponse = isIframePreviewTestMode ? getStubLimit() : limits;
  return {
    limits: transactionLimitsResponse,
    loading
  };
}