import { getIframeLayout } from './queryParams';
export const redirectToUrl = url => {
  const iFrameLayout = getIframeLayout();
  if (iFrameLayout) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    window.top.location.href = url;
  } else {
    window.location.href = url;
  }
};
export const redirectToUrlAsync = (url, delay = 1500) => {
  // setTimeout is used in order to wait for UILoadingButton animation to
  // complete to indicate a successful transaction to the buyer before redirect
  return setTimeout(() => redirectToUrl(url), delay);
};
export const getIsPaymentPurchaseUrl = pathname => {
  return pathname.includes('/purchase/');
};
export const getIsIframePreviewUrl = pathname => {
  return pathname.includes('/iframe/preview');
};
export const getIsIframePreviewTestUrl = pathname => pathname.includes('/iframe/preview/test');
export const getIsIframeableUrl = pathname => {
  return pathname.includes('/iframe/');
};