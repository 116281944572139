'use es6';

import enviro from 'enviro';
import { parse } from './Params';
export const isTopLevelWindowSupported = ({
  enableTopLevelEmbed,
  enableTopLevelEmbedForTestOnly
} = {}, top = window.top) => window === top && (enableTopLevelEmbed || enviro.isQa() && enableTopLevelEmbedForTestOnly);
export const getEmbeddedPropsFromQueryParams = (search = window.location.search) => parse(search);
export const getTopLevelEmbeddedContextDefaults = () => ({
  embedId: 'top',
  group: 'iframeable',
  name: 'embed-top',
  appData: {
    info: {},
    name: 'unknown',
    url: ''
  }
});