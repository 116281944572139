import { useLocation } from 'react-router-dom';
import { usePaymentFormContext } from '../../context/PaymentFormContext';
import { getIsIframePreviewTestUrl } from '../../utils/urls';
export const useIsIframePreviewTestMode = () => {
  const {
    pathname
  } = useLocation();
  const {
    checkoutSession: checkoutSessionMap
  } = usePaymentFormContext();
  const checkoutSession = checkoutSessionMap.toJS();
  const isIframePreviewTestUrl = getIsIframePreviewTestUrl(pathname);
  const isLiveMode = checkoutSession.liveMode;
  return {
    isIframePreviewTestMode: !isLiveMode && isIframePreviewTestUrl
  };
};