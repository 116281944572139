import Raven from 'raven-js';

// eslint-disable-next-line hubspot-dev/no-declarations

export function addGoogleAnalyticsTrackingCode(measurementId) {
  if (!measurementId) {
    Raven.captureMessage('Attempted to instantiate Google Analytics without measurement id');
    return;
  }
  const script = document.createElement('script');
  script.async = true;
  script.type = 'text/javascript';
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  document.head.appendChild(script);
  window.dataLayer = window.dataLayer || [];
  const gtag = function gtag(...__args) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };
  if (!window._hsGoogleConsentRunOnce) {
    window._hsGoogleConsentRunOnce = true;
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied'
    });
    const _hsp = window._hsp = window._hsp || [];
    _hsp.push(['addPrivacyConsentListener', function (consent) {
      const hasAnalyticsConsent = consent && (consent.allowed || consent.categories && consent.categories.analytics);
      const hasAdsConsent = consent && (consent.allowed || consent.categories && consent.categories.advertisement);
      gtag('consent', 'update', {
        ad_storage: hasAdsConsent ? 'granted' : 'denied',
        analytics_storage: hasAnalyticsConsent ? 'granted' : 'denied'
      });
    }]);
  }
  gtag('js', new Date());
  gtag('config', measurementId);
}