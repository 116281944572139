import { getQuickFetchOrMakeRequest } from '../earlyRequester/getQuickFetchOrMakeRequest';
import noAuthClient from 'hub-http/clients/noAuthApiClient';
import { getUtk } from '../utils/analytics';
import { stringifyParams, getQueryParams, getIframeLayout } from '../utils/queryParams';
import { catchAndRethrowNetworkError, catchAndRethrowSensitiveNetworkError
// @ts-expect-error Untyped module
} from 'crm_data/bCommerce/monitoring/errorUtils';
const getParentPageUrl = () => {
  const queryParams = getQueryParams();
  if (!queryParams || !getIframeLayout() || !queryParams.parentPageUrl) {
    return null;
  }
  return queryParams.parentPageUrl;
};
const _createCheckoutSession = () => {
  const publicId = window.location.pathname.split('/payments/')[1].replace('iframe/', '');
  const queryParams = getQueryParams();

  // Send hubspotUtk so BE can return prefilled inputs based on cookie (utk)
  const requestQueryParams = {
    hubspotUtk: getUtk()
  };
  return noAuthClient.post(`sales-checkout/v2/session-templates/public/${publicId}/payments-sessions?${stringifyParams(requestQueryParams)}`, {
    data: {
      customerEmail: queryParams.email || null
    }
  });
};
export const createCheckoutSession = () => {
  return getQuickFetchOrMakeRequest('checkoutSession', _createCheckoutSession).catch(e => {
    // Skip sending error message for 404s - expected for deleted payment links
    if (!e.data.includes('Error 404 Not Found')) {
      catchAndRethrowNetworkError(e);
    }
    throw e;
  });
};
const _fetchCheckoutSession = () => {
  const paymentSessionId = window.location.pathname.split('/purchase/')[1];

  // Send hubspotUtk so BE can return prefilled inputs based on cookie (utk)
  const requestQueryParams = {
    hubspotUtk: getUtk()
  };
  return noAuthClient.get(`/payments-checkout/v1/checkout-sessions/${paymentSessionId}/public?${stringifyParams(requestQueryParams)}`);
};
export const fetchCheckoutSession = () => {
  return getQuickFetchOrMakeRequest('checkoutSession', _fetchCheckoutSession).catch(e => {
    // We don't want to send a Sentry error when a session is expired because this is a known response from BE
    // and FE shows an expected error state so the log is unnecessary.
    if (!e.data.includes('This payment session has expired.')) {
      catchAndRethrowSensitiveNetworkError(e);
    }
    throw e;
  });
};
export const createPaymentIntent = ({
  data
}) => {
  const paymentSessionId = window.location.pathname.split('/purchase/')[1];

  // Send hubspotUtk so formSubmission event is associated with the right user
  // Send parentPageUrl so formSubmission event is associated with the parent CMS page
  const formMetadata = {
    hubspotUtk: getUtk(),
    parentPageUrl: getParentPageUrl()
  };
  return noAuthClient.post(`payments-checkout/v1/checkout-sessions/${paymentSessionId}/intent`, {
    data: Object.assign({}, data, {
      formMetadata
    })
  }).catch(catchAndRethrowSensitiveNetworkError);
};