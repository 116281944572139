import { useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getIsIframePreviewUrl } from '../utils/urls';
import { Map as ImmutableMap } from 'immutable';
import { trackPageFormFieldFirstInteraction } from '../utils/tracking';
import Raven from 'raven-js';
import { INTERACTION_SECTIONS, INTERACTION_TARGETS } from '../constants/PageInteractionTracking';
const usePageInteractionTracking = () => {
  const firstInteractionCacheRef = useRef(ImmutableMap({}));
  const {
    pathname
  } = useLocation();
  const isRouteIframePreview = getIsIframePreviewUrl(pathname);
  const trackFirstFormFieldInteraction = useCallback(({
    section,
    target
  }) => {
    try {
      if (isRouteIframePreview || firstInteractionCacheRef.current.getIn([section, target]) || !(target in INTERACTION_TARGETS) || !(section in INTERACTION_SECTIONS)) {
        return;
      }
      firstInteractionCacheRef.current = firstInteractionCacheRef.current.setIn([section, target], true);
      trackPageFormFieldFirstInteraction(section, target);
    } catch (error) {
      Raven.captureMessage('usePageInteractionTracking error', {
        extra: {
          message: error && error.message
        }
      });
    }
  }, [isRouteIframePreview]);
  return {
    trackFirstFormFieldInteraction
  };
};
export default usePageInteractionTracking;