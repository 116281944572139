import initialState from './state';
import BuyerOverridesActionTypes from './actionTypes';
const buyerOverridesReducer = (state = initialState, action) => {
  switch (action.type) {
    case BuyerOverridesActionTypes.SET_LOADING:
      return Object.assign({}, state, {
        isLoading: action.data.isLoading
      });
    case BuyerOverridesActionTypes.SET_QUANTITY:
      return Object.assign({}, state, {
        quantities: Object.assign({}, state.quantities, {
          [action.data.id]: action.data.quantity
        })
      });
    case BuyerOverridesActionTypes.SET_PRICE:
      return Object.assign({}, state, {
        prices: Object.assign({}, state.prices, {
          [action.data.id]: action.data.price
        })
      });
    case BuyerOverridesActionTypes.SET_EXPIRED_FUTURE_START_DATE_LINE_ITEMS:
      return Object.assign({}, state, {
        expiredFutureStartDateLineItemIds: action.data.expiredFutureStartDateLineItemIds
      });
    case BuyerOverridesActionTypes.SET_PARTIAL_PAYMENT_AMOUNT:
      return Object.assign({}, state, {
        partialPaymentAmount: action.data.partialPaymentAmount
      });
    case BuyerOverridesActionTypes.SELECTION_TOGGLED:
      {
        const nextState = Object.assign({}, state, {
          selections: Object.assign({}, state.selections, {
            [action.data.id]: !state.selections[action.data.id]
          })
        });
        if ('quantity' in action.data) {
          nextState.quantities = Object.assign({}, state.quantities, {
            [action.data.id]: action.data.quantity
          });
        }
        if ('price' in action.data) {
          nextState.prices = Object.assign({}, state.prices, {
            [action.data.id]: action.data.price
          });
        }
        return nextState;
      }
    default:
      return state;
  }
};
export default buyerOverridesReducer;