import Raven from 'raven-js';
export const removeConversationsWidget = () => {
  try {
    window.HubSpotConversations.widget.remove();
  } catch (error) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    Raven.captureException(error, {
      extra: {
        from: 'removeConversationsWidget'
      }
    });
  }
};
export const MAX_ATTEMPTS = 10;
const DELAY_MILLISECONDS = 500;

// Remove the in-app conversations widget to prevent double loading when the payments page is iframed
// on a page that also loads the widget
export const removeConversationsWidgetWhenAvailable = ({
  attemptNum = 1,
  // Some helpers for testing
  disableRetries = false,
  triggerSurveyFunc = removeConversationsWidget
} = {}) => {
  if (attemptNum <= MAX_ATTEMPTS) {
    if (window.HubSpotConversations && window.HubSpotConversations.widget.status().loaded) {
      triggerSurveyFunc();
    } else if (!disableRetries) {
      setTimeout(() => {
        removeConversationsWidgetWhenAvailable({
          attemptNum: attemptNum + 1,
          triggerSurveyFunc
        });
      }, DELAY_MILLISECONDS);
    }
  }
};