import { useState } from 'react';
import { hasCustomConfiguration, isOptional } from '../../types/utils/typeguards';
import { useEffect } from 'react';
import { usePaymentFormContext } from '../../context/PaymentFormContext';
import { useTransactionLimits } from '../useTransactionLimits';
const useEffectBlurWhenAllPaymentMethodFieldsBlurred = onBlurs => {
  // Activating validation on any (not all) non-order-summary fields
  // focused would be more robust, but this works for now.
  const {
    formState
  } = usePaymentFormContext();
  const areRelatedFieldsBlurred = formState.get('allBlurred');
  useEffect(() => {
    if (areRelatedFieldsBlurred) onBlurs.forEach(fn => fn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areRelatedFieldsBlurred]);
};
export const useLineItemBlurs = (lineItems, paymentMethod) => {
  const {
    limits
  } = useTransactionLimits();
  const minimumAmount = limits && limits.minimumAmountForPaymentMethod[paymentMethod];
  const [blurred, _setBlurred] = useState(lineItems.map(
  // Make items with amount > stripe min start as blurred for validation purposes in case the user skips them.
  l => hasCustomConfiguration(l) && minimumAmount && l.amount >= minimumAmount && !isOptional(l)));
  const onBlurs = blurred.map((__, i) => () => {
    _setBlurred([...blurred.slice(0, i), true, ...blurred.slice(i + 1)]);
  });
  useEffectBlurWhenAllPaymentMethodFieldsBlurred(onBlurs);
  return {
    onBlurs,
    blurred
  };
};