import { GET_CHECKOUT_SESSION } from '../data/queries';
// NOTE: we attempt to avoid mixing Immutable and TypeScript, but in this case
// the Apollo cache expects an immutable, so here we cast the merged properties
// to immutable prior to writing to the cache.
const updateCheckoutSessionSubtotals = (client, subtotals) => {
  const cache = client.readQuery({
    query: GET_CHECKOUT_SESSION
  });
  if (!cache) return;
  client.writeQuery({
    query: GET_CHECKOUT_SESSION,
    data: {
      checkoutSession: Object.assign({}, cache.checkoutSession, {
        subtotalsPublicResponse: subtotals,
        lineItems: subtotals.lineItems,
        total: subtotals.firstPaymentTotal
      })
    }
  });
};
export default updateCheckoutSessionSubtotals;