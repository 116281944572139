import I18n from 'I18n';
import { useEffect } from 'react';
import { setDiscountCodeErrorMessage, setDiscountAmount } from '../../state/DiscountCode/discountCodeSlice';
import { useDispatch } from 'react-redux';
import { usePaymentFormContext } from '../../context/PaymentFormContext';

/**
 * @note Used only when there's an error with the code on payment submission - not on code apply
 */
export default function useDiscountCodeError({
  discountCodeError
}) {
  const reduxDispatch = useDispatch();
  const {
    dispatch
  } = usePaymentFormContext();
  useEffect(() => {
    if (discountCodeError) {
      reduxDispatch(setDiscountCodeErrorMessage(I18n.text('payments.invalidDiscountCodeMessage')));
      reduxDispatch(setDiscountAmount({
        discountedValue: 0,
        discountedPercentage: 0
      }));
      dispatch({
        type: 'setIsDiscountCodeApplied',
        data: false
      });
    }
  }, [discountCodeError, reduxDispatch, dispatch]);
}