import { useEffect } from 'react';
import { setIsDiscountInputEnabled, setDiscountAmount } from '../../state/DiscountCode/discountCodeSlice';
import { useDispatch } from 'react-redux';
import { usePaymentFormContext } from '../../context/PaymentFormContext';
export default function useApplyDiscountCodeFromResponse({
  discountCodeResponse,
  oneTimeDiscountAmount
}) {
  const reduxDispatch = useDispatch();
  const {
    dispatch
  } = usePaymentFormContext();
  useEffect(() => {
    if (discountCodeResponse) {
      const {
        discountCode,
        checkoutSessionDiscount
      } = discountCodeResponse;
      const {
        value,
        valueType
      } = checkoutSessionDiscount;
      dispatch({
        type: 'setIsDiscountCodeApplied',
        data: true
      });
      reduxDispatch(setIsDiscountInputEnabled(true));
      reduxDispatch(setDiscountAmount({
        discountedValue: oneTimeDiscountAmount || 0,
        discountedPercentage: valueType === 'PERCENT' ? value : 0
      }));
      dispatch({
        type: 'setDiscountCode',
        data: discountCode
      });
    }
  }, [reduxDispatch, discountCodeResponse, oneTimeDiscountAmount, dispatch]);
}