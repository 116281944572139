import { useSelector } from 'react-redux';
import { lineItemError } from '../constants/OrderSummaryLineItemErrors';
import { isAtCheckoutLineItem, hasCustomConfiguration, isRecurringCheckoutLineItem, isOptional } from '../types/utils/typeguards';
import { getBuyerOverridesIsLoading, getExpiredFutureStartDateLineItemIds } from '../state/BuyerOverrides/selectors';
import { getRecurringBillingFrequency } from '../utils/recurring';
import { useTransactionLimits } from './useTransactionLimits';
const emptySet = new Set();
const stubEmptySet = () => emptySet;

/**
 * This hook returns an array of all errors a configurable lineItem has.
 * eg. for an Order summary of 2 lineItems it returns [Set(0),Set(1)]. Tests are in checkoutContainer-test.js
 */
export const useLineItemErrors = ({
  lineItems,
  blurStates,
  orderTotal,
  readOnly,
  selectedItems,
  paymentMethod
}) => {
  const loadingOverrides = useSelector(getBuyerOverridesIsLoading);
  const {
    limits
  } = useTransactionLimits();
  const minimumAmount = limits && limits.minimumAmountForPaymentMethod[paymentMethod];
  const maximumAmount = limits && limits.maximumAmountForPaymentMethod[paymentMethod];
  const areEditableItemsBlurred = blurStates.every(v => v === true);

  // For optional products - only one item becomes blurred on checkout click, so we see if any items are blurred
  const areSomeEditableItemsBlurred = blurStates.some(v => v === true);
  const areOptionalItemsSelected = selectedItems.some(i => i === true);
  const areAllItemsOptional = lineItems.every(l => hasCustomConfiguration(l) && isOptional(l));
  const areAllLineItemsOptionalUnselected = areAllItemsOptional && !areOptionalItemsSelected;
  const areEditableRecurringItemsPresent = lineItems.some(l => hasCustomConfiguration(l) && isRecurringCheckoutLineItem(l));
  const expiredFutureStartDateLineItemIds = new Set(useSelector(getExpiredFutureStartDateLineItemIds));
  const areEditableRecurringItemsBlurred = areEditableRecurringItemsPresent &&
  // Casting to any due to typescript issue https://github.com/microsoft/TypeScript/issues/44373
  lineItems.every((l, i) => !hasCustomConfiguration(l) || !isRecurringCheckoutLineItem(l) || blurStates[i] === true);
  const areRequiredItemsBlurred = !areEditableItemsBlurred && !areEditableRecurringItemsBlurred && expiredFutureStartDateLineItemIds.size === 0;
  const hideOptionalItemValidation = !areSomeEditableItemsBlurred && !areOptionalItemsSelected;
  const hideValidation = areAllItemsOptional ? hideOptionalItemValidation : areRequiredItemsBlurred;
  const subscriptions = getSubscriptions(lineItems);
  return loadingOverrides || readOnly || hideValidation ? lineItems.map(stubEmptySet) : lineItems.map((lineItem, index) => {
    const err = new Set();

    // skips validation for optional items that are not selected
    if (hasCustomConfiguration(lineItem) && isOptional(lineItem) && !selectedItems[index]) {
      if (areAllLineItemsOptionalUnselected) {
        err.add(lineItemError.ORDER_TOTAL_BELOW_MIN);
      }
      return err;
    }
    // Order total is calculated with only line items billed at checkout.
    if (isAtCheckoutLineItem(lineItem)) {
      if (minimumAmount && orderTotal < minimumAmount) {
        if (areAllLineItemsOptionalUnselected) {
          err.add(lineItemError.ORDER_TOTAL_BELOW_MIN_OPTIONAL);
        }
        err.add(lineItemError.ORDER_TOTAL_BELOW_MIN);
      }
      if (maximumAmount && orderTotal > maximumAmount) {
        err.add(lineItemError.ORDER_TOTAL_TOTAL_ABOVE_MAX);
      }
    } else {
      // Recurring total can be above max limit only if future charges lineItems exist otherwise,
      // it will be caught by the ORDER_TOTAL_ABOVE_MAX condition.
      if (isRecurringCheckoutLineItem(lineItem) && Object.keys(subscriptions).length !== 0) {
        if (maximumAmount && subscriptions[stringifyBillingTerms(lineItem.recurringBillingTerms)].subtotal > maximumAmount) {
          err.add(lineItemError.RECURRING_TOTAL_ABOVE_MAX);
        }
      }
      if (expiredFutureStartDateLineItemIds.has(lineItem.id)) err.add(lineItemError.FUTURE_ITEM_EXPIRED);
    }
    // This is applicable to recurring totals of current charges and future charges.
    if (isRecurringCheckoutLineItem(lineItem) && Object.keys(subscriptions).length !== 0 && minimumAmount && subscriptions[stringifyBillingTerms(lineItem.recurringBillingTerms)].subtotal < minimumAmount) {
      if (areAllItemsOptional && !areOptionalItemsSelected) {
        err.add(lineItemError.ORDER_TOTAL_BELOW_MIN_OPTIONAL);
      }
      err.add(lineItemError.RECURRING_TOTAL_BELOW_MIN);
    }
    return err;
  });
};
function getSubscriptions(lineItems) {
  return lineItems.filter(isRecurringCheckoutLineItem).reduce((acc, lineItem) => {
    const terms = stringifyBillingTerms(lineItem.recurringBillingTerms);
    if (acc[terms]) {
      acc[terms].subtotal += lineItem.amount;
    } else {
      acc[terms] = {
        recurringBillingTerms: lineItem.recurringBillingTerms,
        subtotal: lineItem.amount
      };
    }
    return acc;
  }, {});
}

// Custom stringify function that displays the properties in recurringBillingTerms needed to determine subscriptions
function stringifyBillingTerms({
  intervalLength,
  intervalUnit,
  numPayments,
  initialPayment: {
    type
  }
}) {
  const billingFrequency = getRecurringBillingFrequency(intervalUnit, intervalLength);
  if (!numPayments) {
    return `${type} ${billingFrequency}`;
  }
  return `${type} ${billingFrequency} ${numPayments}`;
}