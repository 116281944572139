export const FORM_FIELD_FIELD_TYPES = {
  BOOLEAN_CHECKBOX: 'booleancheckbox',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  NUMBER: 'number',
  PHONE_NUMBER: 'phonenumber',
  RADIO: 'radio',
  SELECT: 'select',
  TEXT: 'text',
  TEXTAREA: 'textarea'
};
export const FORM_FIELD_NAMES = {
  MOBILE_PHONE: 'mobilephone',
  PHONE: 'phone'
};