import { useEffect } from 'react';
import { getIframeLayout, LAYOUTS } from '../utils/queryParams';
export const ESCAPE_KEY_MESSAGE = 'escapeKeydown';
export const ESCAPE = 'Escape';
// This listener is used in the CMS overlay integration - we want to be able to close the overlay if a user presses the Escape key
function useEscapeListener(sourceId) {
  useEffect(() => {
    function handleKeydown(event) {
      if (event && event.key === ESCAPE) {
        window.parent.postMessage({
          event: ESCAPE_KEY_MESSAGE,
          data: {
            paymentLinkId: sourceId
          }
        }, '*');
      }
    }
    const iFrameLayout = getIframeLayout();
    if (iFrameLayout === LAYOUTS.FULL_PAGE_OVERLAY) {
      window.addEventListener('keydown', handleKeydown);
      return () => window.removeEventListener('keydown', handleKeydown);
    }
    return () => {};
  }, [sourceId]);
}
export default useEscapeListener;