import Raven from 'raven-js';
import enviro from 'enviro';
import { RECAPTCHA_ACH_TYPE, RECAPTCHA_BACS_TYPE, RECAPTCHA_CARD_TYPE, RECAPTCHA_PADS_TYPE, RECAPTCHA_SEPA_TYPE } from '../constants/ReCaptcha';
import { ACH, SEPA, PADS, BACS } from '../constants/PaymentMethods';
const TEST_SITE_KEY = '6Le6rD8kAAAAAFAKCOH96PLU8P5WE1RWz5Sz5Quh';
const PROD_SITE_KEY = '6LdZL7wkAAAAAJ8fv2_ZtTKDmr22AeNATST8naKi';
export function addRecaptchaScript(isATPortal) {
  const isQA = enviro.isQa();
  const siteKey = isQA || isATPortal ? TEST_SITE_KEY : PROD_SITE_KEY;
  const script = document.createElement('script');
  script.async = true;
  script.type = 'text/javascript';
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
  document.head.appendChild(script);
}

// eslint-disable-next-line hubspot-dev/no-declarations

const getActionType = paymentMethod => {
  switch (paymentMethod) {
    case ACH:
      return RECAPTCHA_ACH_TYPE;
    case BACS:
      return RECAPTCHA_BACS_TYPE;
    case PADS:
      return RECAPTCHA_PADS_TYPE;
    case SEPA:
      return RECAPTCHA_SEPA_TYPE;
    default:
      return RECAPTCHA_CARD_TYPE;
  }
};
export const submitPaymentWithRecaptcha = (isATPortal, submitPayment, paymentMethod) => {
  const isQA = enviro.isQa();
  const siteKey = isQA || isATPortal ? TEST_SITE_KEY : PROD_SITE_KEY;
  const action = getActionType(paymentMethod);
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha) {
      Raven.captureMessage(`window.grecaptcha failed to load, submitting payment with null token`);
      resolve(submitPayment(null));
    }
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(siteKey, {
        action
      }).then(recaptchaToken => {
        submitPayment(recaptchaToken);
      }).catch(err => {
        reject(Raven.captureMessage(`The grecaptcha.enterprise.execute failed with error: ${err}`));
      });
    });
  });
};
export const getIsRecaptchaEnabled = checkoutSession => checkoutSession.get('liveMode') && checkoutSession.getIn(['checkoutIntegrations', 'recaptchaIntegration', 'isEnabled']);