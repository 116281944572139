'use es6';

import I18n from 'I18n';
import { allCountries } from 'I18n/constants/TelephoneData';
export default (code => {
  const countries = allCountries;
  const getCountryName = countryCode => I18n.text(`SharedI18nStrings.countryCodes.${countryCode.toUpperCase()}`);
  const translatedCountries = I18n.text('SharedI18nStrings.countryCodes');
  if (translatedCountries[code.toUpperCase()]) {
    return getCountryName(code);
  }
  const countryData = countries.find(country => country.iso2 === code.toLowerCase());
  if (countryData) {
    return countryData.name;
  }
  return I18n.text('i18nAddon.countryInfo.invalidCountryOption');
});