'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["id", "timeout", "message", "titleText", "type", "sticky"];
import { postNotification, removeAllNotifications, removeNotification } from '../actions/NotificationActions';
import { FLOATING_ALERT_DEFAULT_TIMING } from 'HubStyleTokens/times';
const defaultTimeout = parseInt(FLOATING_ALERT_DEFAULT_TIMING, 10);
let defaultId = 0;
const timeouts = {};
export default (store => next => action => {
  const {
    dispatch
  } = store;
  const result = next(action);
  if (!action.meta) {
    return result;
  }
  if (action.meta.removeNotification && action.meta.removeNotification.id) {
    dispatch(removeNotification(action.meta.removeNotification.id));
  }
  if (action.meta.removeAllNotifications) {
    dispatch(removeAllNotifications());
  }
  if (!action.meta.notification) {
    return result;
  }
  const _action$meta$notifica = action.meta.notification,
    {
      id,
      timeout,
      message,
      titleText,
      type,
      sticky
    } = _action$meta$notifica,
    rest = _objectWithoutPropertiesLoose(_action$meta$notifica, _excluded);
  const notificationId = id || `ReduxMessenger-${defaultId++}`;
  const notification = Object.assign({}, rest, {
    id: notificationId,
    message,
    sticky: !!sticky,
    timeout: timeout != null ? timeout : defaultTimeout,
    timestamp: Date.now(),
    titleText,
    type
  });
  if (!sticky && type !== 'danger') {
    // clear any existing timeouts. If another alert is being
    // created for the same id, the timeout should be reset.
    clearTimeout(timeouts[notificationId]);
    timeouts[notificationId] = setTimeout(() => {
      dispatch(removeNotification(notification.id));
    }, notification.timeout);
  }
  dispatch(postNotification(notification));
  return result;
});