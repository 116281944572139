import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataFetchingClient, useQuery } from 'data-fetching-client';
import { CREATE_BUYER_OVERRIDES_SUBTOTALS, CREATE_BUYER_OVERRIDES_PREVIEW_SUBTOTALS } from '../data/queries';
import addGenericFloatingError from '../utils/addGenericFloatingError';
import updateCheckoutSessionSubtotals from '../utils/updateCheckoutSessionSubtotals';
import BuyerOverridesActionTypes from '../state/BuyerOverrides/actionTypes';
import { getBuyerOverridesLineItems, getBuyerOverridesPartialPaymentAmount } from '../state/BuyerOverrides/selectors';
function useUpdateBuyerOverridesLoading(results) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: BuyerOverridesActionTypes.SET_LOADING,
      data: {
        isLoading: results.loading
      }
    });
  }, [dispatch, results.loading]);
}
// for the checkout page, after we have a checkoutSessionId from an existing payment link
export function useFetchDynamicSubtotals({
  checkoutSession,
  skip = false
}) {
  const client = useDataFetchingClient();
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  const partialPaymentAmountForSubtotals = useGetPartialPaymentAmountForSubtotals({
    checkoutSession
  });
  const result = useQuery(CREATE_BUYER_OVERRIDES_SUBTOTALS, {
    skip,
    variables: {
      buyerOverrides: {
        discountCode: checkoutSession.getIn(['subtotalsPublicResponse', 'discountCodeResponse', 'discountCode']),
        lineItems: buyerOverridesLineItems,
        paymentAmount: partialPaymentAmountForSubtotals
      },
      paymentSessionId: checkoutSession.get('id')
    },
    onCompleted: ({
      subtotals
    }) => {
      updateCheckoutSessionSubtotals(client, subtotals);
    },
    onError: addGenericFloatingError
  });
  useUpdateBuyerOverridesLoading(result);
  return result;
}
// for the preview iframe, before we have a checkoutSessionId because we're creating a payment link
export function useFetchDynamicSubtotalsPreview({
  checkoutSession,
  discount,
  adjustments,
  portalId,
  currencyCode,
  lineItems,
  skip = false
}) {
  const client = useDataFetchingClient();
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  const partialPaymentAmountForSubtotals = useGetPartialPaymentAmountForSubtotals({
    checkoutSession
  });
  const result = useQuery(CREATE_BUYER_OVERRIDES_PREVIEW_SUBTOTALS, {
    skip,
    variables: {
      buyerOverrides: {
        discountCode: checkoutSession.getIn(['subtotalsPublicResponse', 'discountCodeResponse', 'discountCode']),
        lineItems: buyerOverridesLineItems,
        paymentAmount: partialPaymentAmountForSubtotals
      },
      currencyCode,
      lineItems,
      portalId,
      adjustments,
      discount
    },
    onCompleted: ({
      subtotals
    }) => updateCheckoutSessionSubtotals(client, subtotals),
    onError: addGenericFloatingError
  });
  useUpdateBuyerOverridesLoading(result);
  return result;
}
export function useGetPartialPaymentAmountForSubtotals({
  checkoutSession
}) {
  const partialPaymentsInfo = checkoutSession.get('partialPaymentsInfo') && checkoutSession.get('partialPaymentsInfo').toJS();
  const dispatch = useDispatch();
  const partialPaymentAmount = useSelector(getBuyerOverridesPartialPaymentAmount);
  const initialPartialPaymentAmount = partialPaymentsInfo && partialPaymentsInfo.isPartialPaymentsEnabled && partialPaymentsInfo.balanceDue || undefined;
  const partialPaymentAmountForSubtotals = partialPaymentAmount || initialPartialPaymentAmount;

  // initializes partialPaymentAmount to balanceDue on first page load
  // when `partialPaymentAmount` is undefined
  if (!partialPaymentAmount && initialPartialPaymentAmount) {
    dispatch({
      type: BuyerOverridesActionTypes.SET_PARTIAL_PAYMENT_AMOUNT,
      data: {
        partialPaymentAmount: initialPartialPaymentAmount
      }
    });
  }
  return partialPaymentAmountForSubtotals;
}