import { ACH_METHOD, APPLE_PAY_METHOD, BACS_METHOD, CARD_METHOD, GOOGLE_PAY_METHOD, PADS_METHOD, SEPA_METHOD } from '../constants/PaymentElement';
import { ACH, BACS, CARD, PADS, SEPA } from '../constants/PaymentMethods';
export const getValidPaymentMethods = (paymentMethods, paymentAmount, maximumAmountForPaymentMethod) => paymentMethods.map(paymentMethod => {
  switch (paymentMethod) {
    case paymentAmount <= maximumAmountForPaymentMethod[CARD] && CARD:
      return CARD_METHOD;
    case paymentAmount <= maximumAmountForPaymentMethod[ACH] && ACH:
      return ACH_METHOD;
    case paymentAmount <= maximumAmountForPaymentMethod[BACS] && BACS:
      return BACS_METHOD;
    case paymentAmount <= maximumAmountForPaymentMethod[PADS] && PADS:
      return PADS_METHOD;
    case paymentAmount <= maximumAmountForPaymentMethod[SEPA] && SEPA:
      return SEPA_METHOD;
    default:
      return null;
  }
}).filter(paymentMethod => typeof paymentMethod === 'string');
export const isCardOrWallet = paymentMethod => {
  return paymentMethod === CARD_METHOD || paymentMethod === GOOGLE_PAY_METHOD || paymentMethod === APPLE_PAY_METHOD;
};
export const isACHorSEPA = paymentMethod => {
  return paymentMethod === ACH || paymentMethod === SEPA;
};
export const isACHorPADS = paymentMethod => {
  return paymentMethod === ACH || paymentMethod === PADS;
};
export const isCardorACH = paymentMethod => {
  return paymentMethod === CARD || paymentMethod === ACH;
};
export const stripeToHSPaymentMethod = stripePaymentMethod => {
  switch (stripePaymentMethod) {
    case ACH_METHOD:
      return ACH;
    case BACS_METHOD:
      return BACS;
    case PADS_METHOD:
      return PADS;
    case SEPA_METHOD:
      return SEPA;
    // Apple/Google Pay is considered CARD as well
    default:
      return CARD;
  }
};
export const hsToStripePaymentMethod = paymentMethod => {
  switch (paymentMethod) {
    case CARD:
      return CARD_METHOD;
    case ACH:
      return ACH_METHOD;
    case BACS:
      return BACS_METHOD;
    case PADS:
      return PADS_METHOD;
    case SEPA:
      return SEPA_METHOD;
    default:
      return undefined;
  }
};