import { getIframeLayout, LAYOUTS } from './queryParams';

// This is used by the CMS to handle page actions post checkout submission
export const sendPostMessage = (event, sourceId) => {
  const iFrameLayout = getIframeLayout();
  if (iFrameLayout === LAYOUTS.FULL_PAGE_OVERLAY) {
    window.parent.postMessage({
      event,
      data: {
        paymentLinkId: sourceId
      }
    }, '*');
  }
};