import { CALYPSO_DARK, SLINKY, OBSIDIAN, EERIE, BATTLESHIP, FOCUS_RING_BASE } from 'HubStyleTokens/colors';
import { WEB_FONT_MEDIUM_WEIGHT, WEB_FONT_REGULAR_WEIGHT } from 'HubStyleTokens/misc';

//Used for Payment Element react component configuration
export const NEVER = 'never';
export const AUTO = 'auto';
export const TABS_LAYOUT = 'tabs';

// Payment method types
export const ACH_METHOD = 'us_bank_account';
export const CARD_METHOD = 'card';
export const GOOGLE_PAY_METHOD = 'google_pay';
export const APPLE_PAY_METHOD = 'apple_pay';
export const SEPA_METHOD = 'sepa_debit';
export const BACS_METHOD = 'bacs_debit';
export const PADS_METHOD = 'acss_debit';

// Setup vs Payment intent
export const PAYMENT = 'payment';
export const SETUP = 'setup';

// For recurring payments
export const OFF_SESSION = 'off_session';
export const ON_SESSION = 'on_session';

// Method of ACH verification
// 'instant_or_skip" correlated to Financial Connections used for HSP
// 'skip' for manual account entry (no microdeposits) used for BYOS
export const FC_VERIFICATION_METHOD = 'instant_or_skip';
export const MANUAL_VERIFICATION_METHOD = 'skip';

// processors
export const HS_PAYMENTS = 'HS_PAYMENTS';
export const BYO_STRIPE = 'BYO_STRIPE';

// Since Stripe doesn't load Lexend Deca by default in their iframe, we provide it here
export const ELEMENTS_FONTS = [{
  cssSrc: 'https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;400;500;600;700;900&display=swap'
}];
const FORM_FOCUS_STYLE = {
  borderColor: 'rgba(0, 208, 228, 0.5)',
  boxShadow: `0 0 4px 1px rgba(0, 208, 228, 0.3), 0 0 0 1px ${FOCUS_RING_BASE}`,
  outline: '0'
};

// https://stripe.com/docs/elements/appearance-api
export const ELEMENTS_APPEARANCE = {
  variables: {
    colorPrimary: CALYPSO_DARK,
    colorText: '#30313d',
    colorDanger: 'rgb(242, 84, 91)',
    fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
    fontSizeSm: '12px',
    fontWeightNormal: WEB_FONT_REGULAR_WEIGHT,
    fontWeightBold: WEB_FONT_MEDIUM_WEIGHT,
    fontWeightMedium: WEB_FONT_MEDIUM_WEIGHT,
    colorTextPlaceholder: EERIE,
    borderRadius: '3px'
  },
  rules: {
    '.Label': {
      fontWeight: WEB_FONT_MEDIUM_WEIGHT,
      color: OBSIDIAN,
      fontSize: '14px'
    },
    '.Tab': {
      color: SLINKY,
      boxShadow: 'none',
      borderColor: BATTLESHIP
    },
    '.Tab--selected': FORM_FOCUS_STYLE,
    '.TabLabel--selected': {
      color: OBSIDIAN
    },
    '.Tab:hover': {
      color: OBSIDIAN
    },
    '.TabIcon': {
      fill: SLINKY
    },
    '.TabIcon--selected': {
      fill: CALYPSO_DARK
    },
    '.TabIcon:hover': {
      fill: OBSIDIAN
    },
    '.Input': {
      borderColor: BATTLESHIP,
      boxShadow: 'none'
    },
    '.Input:focus': FORM_FOCUS_STYLE,
    '.Error': {
      fontSize: '12px',
      fontWeight: WEB_FONT_MEDIUM_WEIGHT
    }
  }
};