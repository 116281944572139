import { AT_CHECKOUT, FUTURE_START_DATE, RELATIVE_START_DATE } from '../../constants/LineItems';
import { isSome } from './helpers';
export const isFutureStartDateBillingTerms = billingTerms => Boolean(billingTerms && billingTerms.initialPayment.type === FUTURE_START_DATE);
export const isRelativeStartDateBillingTerms = billingTerms => Boolean(billingTerms && billingTerms.initialPayment.type === RELATIVE_START_DATE);
export const hasCustomConfiguration = item => item.customConfiguration !== null;
export const isOptional = item => isSome(item.customConfiguration) && item.customConfiguration.isOptional;
export const isRecurringCheckoutLineItem = lineItem => 'recurringBillingTerms' in lineItem && lineItem.recurringBillingTerms !== null;
export const isAtCheckoutLineItem = lineItem => !('recurringBillingTerms' in lineItem) || lineItem.recurringBillingTerms === null || lineItem.recurringBillingTerms.initialPayment && lineItem.recurringBillingTerms.initialPayment.type === AT_CHECKOUT;
export const isFutureStartDateCheckoutLineItem = lineItem => 'recurringBillingTerms' in lineItem && !!lineItem.recurringBillingTerms && lineItem.recurringBillingTerms.initialPayment.type === FUTURE_START_DATE;
export const isRelativeStartDateCheckoutLineItem = lineItem => 'recurringBillingTerms' in lineItem && !!lineItem.recurringBillingTerms && lineItem.recurringBillingTerms.initialPayment.type === RELATIVE_START_DATE;
export const isFutureBilledCheckoutLineItem = lineItem => isFutureStartDateCheckoutLineItem(lineItem) || isRelativeStartDateCheckoutLineItem(lineItem);
export const isFutureBilledCheckoutLineItems = lineItems => lineItems.every(li => isFutureBilledCheckoutLineItem(li));
export const isFutureBilledSubtotal = subtotal => subtotal.recurringBillingTerms.initialPayment.type === 'FUTURE_START_DATE' || subtotal.recurringBillingTerms.initialPayment.type === 'RELATIVE_START_DATE';
export const isLineItemWithTax = lineItem => lineItem.tax && lineItem.taxAmount;
export const isFormFieldInputImmutableStringList = formFieldInput => {
  return !!formFieldInput && formFieldInput.size !== undefined;
};