export const LAYOUTS = {
  EMBED: 'embed',
  EMBED_FULL: 'embed-full',
  FULL_PAGE_OVERLAY: 'full-page-overlay'
};
export function getQueryParams() {
  const queryString = window.location.search.substr(1);
  if (!queryString) {
    return {};
  }
  return queryString.split('&').reduce((map, pairString) => {
    const pairArray = pairString.split('=');
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    map[pairArray[0]] = decodeURIComponent(pairArray[1]);
    return map;
  }, {});
}
export function stringifyParams(params) {
  return Object.keys(params).map(key => {
    return `${key}=${encodeURIComponent(params[key])}`;
  }).join('&');
}
export function getIframeLayout() {
  const queryParams = getQueryParams();
  return queryParams.layout;
}
export function getReferrer() {
  const queryParams = getQueryParams();
  return queryParams.referrer;
}

/**
 * When loading the checkout app in embed mode, the embed code will pass down
 * the hsVars object as a query param to our iframe. hsVars contains properties
 * about the parent CMS page, such as the pageId, trackingType, etc. These
 * properties will be used so our formView analytic event, when sent in embed
 * mode, is associated with the parent CMS page instead of the iframe.
 */
export function getHsVars() {
  const queryParams = getQueryParams();
  if (!queryParams.hsVars) {
    return {};
  }
  try {
    return JSON.parse(queryParams.hsVars);
  } catch (e) {
    console.error('Unable to parse hsVars', queryParams.hsVars);
    return {};
  }
}