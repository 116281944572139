export const RECURRING_BILLING_FREQUENCIES = {
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  TWO_WEEKS: 'TWO_WEEKS',
  MONTHS: 'MONTHS',
  QUARTERS: 'QUARTERS',
  SIX_MONTHS: 'SIX_MONTHS',
  YEARS: 'YEARS',
  TWO_YEARS: 'TWO_YEARS',
  THREE_YEARS: 'THREE_YEARS'
};