import noAuthClient from 'hub-http/clients/noAuthApiClient';
import { getQuickFetchOrMakeRequest } from '../earlyRequester/getQuickFetchOrMakeRequest';
// @ts-expect-error Untyped module
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';
const _fetchTransactionLimits = (portalId, currency) => {
  return noAuthClient.get(`payments-checkout/v1/checkout-sessions/by-portal/${portalId}/limits?currency=${currency}`);
};
export const fetchTransactionLimits = ({
  portalId,
  currency
}) => {
  return getQuickFetchOrMakeRequest('limits', () => _fetchTransactionLimits(portalId, currency)).catch(catchAndRethrowNetworkError);
};