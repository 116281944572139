'use es6';

import { BASE_BACKGROUND_COLOR_MEDIUM } from 'HubStyleTokens/theme';
export const CARD_BACKGROUND = BASE_BACKGROUND_COLOR_MEDIUM;
export const DEVICE_CLASSES = {
  mobile: 'private-template--mobile',
  tablet: 'private-template--tablet',
  desktop: 'private-template--desktop'
};
export const LAYOUT_CLASSES = {
  'full-width': 'private-template--full-width',
  'max-width': 'private-template--centered'
};
export const LAYOUT_TYPES = ['flex', 'grid'];