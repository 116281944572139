import { useQuery } from 'data-fetching-client';
import { GET_POLICY_SETTINGS } from '../data/queries';
export function useMerchantPolicySettings(checkoutSession) {
  const {
    loading,
    data
  } = useQuery(GET_POLICY_SETTINGS, {
    variables: {
      portalId: checkoutSession.portalId
    }
  });
  const displayAcknowledgementCheckbox = !!data && data.policySettings.customPolicyEnabled && data.policySettings.acknowledgementRequired;
  return {
    loading,
    policySettings: data && data.policySettings,
    displayAcknowledgementCheckbox
  };
}