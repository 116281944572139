import sizeof from './object-sizeof';
import { ReduxStoreSizeMetrics } from '../Metrics';
let ACTIONS_COUNTER = 0;
let isTimeoutInitialized = false;
const getStoreSize = store => {
  return sizeof(store.getState());
};
const reportStoreData = (store, action) => {
  isTimeoutInitialized = true;
  ReduxStoreSizeMetrics.histogram(`redux-store-size`).update(getStoreSize(store));
  ReduxStoreSizeMetrics.histogram(`redux-actions-counter`).update(ACTIONS_COUNTER);
  setTimeout(() => {
    isTimeoutInitialized = false;
    reportStoreData(store, action);
    ACTIONS_COUNTER = 0;
  }, 60000); // Report store size every minute, as this is as fast as metrics-js reports
};
const logReduxStoreSizeMiddleware = store => next => action => {
  ACTIONS_COUNTER++; // Increment the actions counter on every dispatch

  // Only allow one instance of the store size computation and reporting timeout at any given time
  if (!isTimeoutInitialized) {
    reportStoreData(store, action);
  }
  return next(action);
};
export { logReduxStoreSizeMiddleware as default, getStoreSize };