import { AUTO, NEVER } from '../constants/PaymentElement';
import { ACH, BACS, CARD, PADS, SEPA } from '../constants/PaymentMethods';
import { isCardorACH } from './paymentMethods';
export const getBillingNameSetting = paymentMethod => {
  switch (paymentMethod) {
    case CARD:
      return NEVER;
    case ACH:
      return NEVER;
    case BACS:
      return AUTO;
    case PADS:
      return NEVER;
    case SEPA:
      return AUTO;
    default:
      return NEVER;
  }
};
export const getBillingFieldSetting = (collectFullBillingAddress, collectShippingAddress, paymentMethod) => (collectFullBillingAddress || collectShippingAddress) && isCardorACH(paymentMethod) ? NEVER : AUTO;