import { createTracker } from 'usage-tracker-public';
import events from '../../events.yaml';
import Raven from 'raven-js';
import { Metrics } from 'payments-ui-app/Metrics';
import { getIframeLayout, getReferrer } from './queryParams';
import { getLineItemRecurringFrequency } from './recurring';
import { INTERACTION_SECTIONS } from '../constants/PageInteractionTracking';
import { isOptional } from '../types/utils/typeguards';
import { trackSubscriptionCreation } from './subscriptionTracking';
import keyBy from 'hs-lodash/keyBy';
import { isFutureStartDateBillingTerms, isRelativeStartDateBillingTerms } from '../types/utils/typeguards';
export const PAYMENT_SCHEDULE_MARKER = 'PaymentScheduleInstallment';
let tracker;
let beaconTracker;
export function createUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let v;
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    if (c === 'x') {
      v = r;
    } else {
      // eslint-disable-next-line no-bitwise
      v = r & 0x3 | 0x8;
    }
    return v.toString(16);
  });
}
export function initializePaymentsTracker({
  portalId,
  sourceUrl,
  workflow,
  merchantSupportEmail
}) {
  tracker = createTracker({
    events,
    properties: {
      namespace: 'paymentsui',
      hstc: null,
      hubId: portalId,
      email: null,
      sourceUrl,
      workflow,
      merchantSupportEmail,
      referrer: getReferrer(),
      deviceId: createUuid()
    },
    onError: err => {
      Raven.captureMessage('payments-ui tracker event error', {
        extra: {
          message: err.message
        }
      });
    }
  });
  beaconTracker = tracker.clone({});
}
export function track(event, eventProps = {}) {
  if (!tracker) {
    throw new Error('Tracker has not been initialized');
  }
  tracker.track(event, Object.assign({}, eventProps, {
    screen: getIframeLayout() ? 'embedded' : 'public'
  }));
}
export function trackImmediately(event, eventProps = {}) {
  if (!beaconTracker) {
    throw new Error('Beacon tracker has not been initialized');
  }
  beaconTracker.track(event, Object.assign({}, eventProps, {
    screen: getIframeLayout() ? 'embedded' : 'public'
  }));
}
function getEventPropsFromFormState(formState) {
  const paymentMethod = formState.get('paymentMethod');
  const customFormFields = Object.keys(formState.get('customData').toJS());
  if (formState.get('discountCode')) {
    customFormFields.push('discount_code');
  }
  const collectShippingAddress = formState.get('shippingAddress').some(shippingAddressField => Boolean(shippingAddressField));
  const isBillingSameAsShipping = formState.get('billingSameAsShipping');
  return {
    paymentMethod,
    customFormFields,
    collectShippingAddress,
    isBillingSameAsShipping
  };
}
function getSalesTaxAndFeePropsFromAdjustments(adjustments) {
  let hasSalesTax = false;
  let hasOneTimeFees = false;
  for (const adjustment of adjustments) {
    if (hasSalesTax && hasOneTimeFees) {
      return {
        hasSalesTax,
        hasOneTimeFees
      };
    }
    if (adjustment.adjustmentType === 'TAX') {
      hasSalesTax = true;
    } else if (adjustment.adjustmentType === 'FEE') {
      hasOneTimeFees = true;
    }
  }
  return {
    hasSalesTax,
    hasOneTimeFees
  };
}
export function getSalesTaxType(paymentSession) {
  if (paymentSession.get('commerceTaxCalculationId')) {
    return 'AUTO';
  }
  const {
    hasSalesTax
  } = getSalesTaxAndFeePropsFromAdjustments(paymentSession.get('adjustments').toJS());
  return hasSalesTax ? 'MANUAL' : 'NONE';
}
function getUniqueBillingFrequencyArray(recurringLineItems) {
  const uniqueBillingFrequencies = new Set();
  recurringLineItems === null || recurringLineItems === void 0 || recurringLineItems.forEach(lineItem => {
    const billingFrequency = getLineItemRecurringFrequency(lineItem);
    if (billingFrequency) {
      uniqueBillingFrequencies.add(billingFrequency);
    }
  });
  return Array.from(uniqueBillingFrequencies);
}
function getEventPropsFromPaymentSession(paymentSession) {
  const processorType = paymentSession.get('processorType');
  const currencyCode = paymentSession.get('currencyCode');
  const numLineItems = paymentSession.get('lineItems').size;
  const numProductLineItems = paymentSession.get('lineItems').filter(lineItem => Boolean(lineItem.get('sourceId'))).size;
  const numCustomLineItems = numLineItems - numProductLineItems;
  const numOneTimeLineItems = paymentSession.get('lineItems').filter(lineItem => !lineItem.get('recurringBillingTerms')).size;
  const numRecurringLineItems = numLineItems - numOneTimeLineItems;
  const numLineItemsEditable = paymentSession.get('lineItems').filter(lineItem => !!lineItem.getIn(['customConfiguration', 'hasEditableQuantity'])).size;
  const numLineItemsOpenAmount = paymentSession.get('lineItems').filter(lineItem => !!lineItem.getIn(['customConfiguration', 'hasEditablePrice'])).size;
  const numLineItemsWithTax = paymentSession.get('lineItems').filter(lineItem => !!lineItem.get('tax')).size;
  const requiredCheckout = !paymentSession.get('lineItems').some(lineItem => !!lineItem.getIn(['customConfiguration', 'isOptional']));
  const paymentScheduleEnabled = paymentSession.get('lineItems').some(lineItem => {
    const sourceId = lineItem.get('sourceId');
    return !!sourceId && sourceId.includes(PAYMENT_SCHEDULE_MARKER);
  });
  const billingFrequency = getUniqueBillingFrequencyArray(paymentSession.get('lineItems').filter(lineItem => Boolean(lineItem.get('recurringBillingTerms'))).toJS());
  const {
    hasOneTimeFees
  } = getSalesTaxAndFeePropsFromAdjustments(paymentSession.get('adjustments').toJS());
  const salesTax = getSalesTaxType(paymentSession);
  const hasSuccessUrl = Boolean(paymentSession.get('successUrl'));
  const sourceApp = paymentSession.get('sourceApp');
  const paymentType = paymentSession.get('processorType');
  const sourceId = paymentSession.get('sourceId');
  const hasDescriptionHtml = Boolean(paymentSession.get('descriptionHtml'));
  const lineItems = paymentSession.get('lineItems').toJS();
  const numLineItemsScheduled = lineItems.filter(li => Boolean(li.recurringBillingTerms && (isFutureStartDateBillingTerms(li.recurringBillingTerms) || isRelativeStartDateBillingTerms(li.recurringBillingTerms)))).length;
  const isPaymentMethodOnFileEnabled = paymentSession.get('collectPaymentMethodOnFile');
  return {
    currencyCode,
    numLineItems,
    numLineItemsEditable,
    numLineItemsOpenAmount,
    numLineItemsWithTax,
    numLineItemsScheduled,
    requiredCheckout,
    numProductLineItems,
    numCustomLineItems,
    numOneTimeLineItems,
    numRecurringLineItems,
    billingFrequency,
    hasSuccessUrl,
    paymentSessionId: paymentSession.get('id'),
    processorType,
    sourceApp,
    sourceId,
    hasDescriptionHtml,
    salesTax,
    oneTimeFeesAmount: hasOneTimeFees,
    paymentType,
    paymentScheduleEnabled,
    isPaymentMethodOnFileEnabled
  };
}
function getEventPropsFromBuyerOverridesLineItems(buyerOverridesLineItems, checkoutSessionLineItems) {
  const overridesById = keyBy(buyerOverridesLineItems, 'id');
  return checkoutSessionLineItems.reduce((acc, l) => {
    const optional = l.customConfiguration && isOptional(l);
    if (optional) ++acc.numLineItemsOptional;
    if (!(l.id in overridesById)) return acc;
    if (optional) ++acc.numLineItemsOptionalSelected;
    const override = overridesById[l.id];
    if ('quantity' in override) {
      ++acc.numLineItemsEdited;
      ++acc.numLineItemsEditedQuantity;
    } else if ('price' in override) {
      ++acc.numLineItemsEdited;
      ++acc.numLineItemsEditedOpenAmount;
      ++acc.dealPipelineStageId;
    }
    return acc;
  }, {
    numLineItemsEditedOpenAmount: 0,
    numLineItemsEditedQuantity: 0,
    numLineItemsOptional: 0,
    numLineItemsOptionalSelected: 0,
    numLineItemsEdited: 0,
    dealPipelineStageId: 0
  });
}
function getOrderLevelDiscount(checkoutSession) {
  try {
    if (!checkoutSession.orderDiscount || checkoutSession.orderDiscount.discountCode) {
      return {};
    }
    const {
      valueType,
      value
    } = checkoutSession.orderDiscount;
    return {
      discountsApplied: valueType,
      totalDiscountsRate: value
    };
  } catch (e) {
    return {};
  }
}
export function trackSuccessfulPaymentSubmission(formState, paymentSession, buyerOverridesLineItems, walletType) {
  const session = paymentSession.toJS();
  Metrics.counter('successful_checkout_submission').increment();
  trackImmediately('paymentsCheckoutSubmission', Object.assign({
    action: 'successfulPaymentSubmission',
    screen: getIframeLayout() ? 'embedded' : 'public'
  }, getEventPropsFromFormState(formState), getEventPropsFromPaymentSession(paymentSession), getEventPropsFromBuyerOverridesLineItems(buyerOverridesLineItems, paymentSession.get('lineItems').toJS()), getOrderLevelDiscount(session), {
    walletType
  }));
  trackSubscriptionCreation(session);
}
export function trackPageFormFieldFirstInteraction(section, target) {
  Metrics.counter('checkout_page_interaction').increment();
  track('pageInteraction', {
    action: 'firstInteraction',
    type: 'form',
    section,
    target
  });
}
export function getPageInteractionSections(paymentSession) {
  const interactionSections = [INTERACTION_SECTIONS.contactInfo, INTERACTION_SECTIONS.billingAddress];
  try {
    const paymentOptions = paymentSession.get('paymentMethods').map(paymentMethod => paymentMethod.get('paymentMethod'));
    if (paymentSession.get('shippableCountries') && paymentSession.get('shippableCountries').size) {
      // @ts-expect-error make INTERACTION_SECTIONS an enum, and interactionSections type Array<INTERACTION_SECTIONS>;
      interactionSections.push(INTERACTION_SECTIONS.shippingAddress);
    }
    if (paymentOptions.size && paymentOptions.includes('CARD')) {
      // @ts-expect-error make INTERACTION_SECTIONS an enum, and interactionSections type Array<INTERACTION_SECTIONS>;
      interactionSections.push(INTERACTION_SECTIONS.cardInfo);
    }
    if (paymentOptions.size && paymentOptions.includes('ACH')) {
      // @ts-expect-error make INTERACTION_SECTIONS an enum, and interactionSections type Array<INTERACTION_SECTIONS>;
      interactionSections.push(INTERACTION_SECTIONS.bankInfo);
    }
    if (paymentSession.get('collectFullBillingAddress')) {
      // @ts-expect-error make INTERACTION_SECTIONS an enum, and interactionSections type Array<INTERACTION_SECTIONS>;
      interactionSections.push(INTERACTION_SECTIONS.fullBillingAddress);
    }
  } catch (error) {
    Raven.captureMessage('getPageInteractionSections error', {
      extra: {
        message: error.message
      }
    });
  }
  return interactionSections;
}