import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import userInfo from 'hub-http/userInfo';
import { createTracker } from 'usage-tracker';
import events from './events.yaml';
const DEFAULT_TRACKER_PROPS = {
  trackerName: 'commerce-subscriptions-tracker',
  events,
  properties: {
    namespace: 'commerce-subscription',
    email: () => userInfo({
      cached: true,
      ignoreRedirect: true
    }).then(({
      user
    }) => user.email).catch(() => 'unknown'),
    hubId: () => {
      var _PortalIdParser$get;
      return (_PortalIdParser$get = PortalIdParser.get()) !== null && _PortalIdParser$get !== void 0 ? _PortalIdParser$get : -1;
    }
  },
  lastKnownEventProperties: ['app', 'paymentType'],
  debug: () => enviro.debug('commerce-subscription-lib'),
  preserveTrackerEvents: true,
  bypassPool: true
};
const tracker = createTracker(DEFAULT_TRACKER_PROPS);
function track(app, eventName, values, internalTracker = tracker) {
  if (values instanceof Array) {
    values.forEach(value => {
      internalTracker.track(eventName, Object.assign({}, value, {
        app
      }));
    });
  } else {
    internalTracker.track(eventName, Object.assign({}, values, {
      app
    }));
  }
}
const createCustomTracker = customTrackerConfig => {
  var _customTrackerConfig$;
  return createTracker(Object.assign({}, DEFAULT_TRACKER_PROPS, customTrackerConfig, {
    properties: Object.assign({}, DEFAULT_TRACKER_PROPS.properties, (_customTrackerConfig$ = customTrackerConfig.properties) !== null && _customTrackerConfig$ !== void 0 ? _customTrackerConfig$ : {})
  }));
};
export function getTracker(app,
// typescript trick to allow autocomplete of known strings
customTrackerConfig) {
  const customTracker = createCustomTracker(customTrackerConfig !== null && customTrackerConfig !== void 0 ? customTrackerConfig : {});
  return (eventName, values) => customTrackerConfig ? track(app, eventName, values, customTracker) : track(app, eventName, values);
}