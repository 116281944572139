module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "public",
        "embedded"
      ],
      "subscreen": "string",
      "paymentSessionId": "string",
      "interactionSections": {
        "type": "array",
        "isOptional": true
      },
      "numContactFormFields": {
        "type": "number"
      },
      "numLineItemsEditable": {
        "type": "number"
      },
      "numLineItemsWithTax": {
        "type": "number"
      },
      "numLineItemsOpenAmount": {
        "type": "number"
      },
      "dealPipelineStageId": {
        "type": "number"
      },
      "discountShown": {
        "type": "boolean"
      },
      "requiredCheckout": {
        "type": "boolean"
      },
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "paymentScheduleEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "isPaymentMethodOnFileEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "sourceApp": {
        "type": "string"
      },
      "referrer": {
        "type": "string",
        "isOptional": true
      },
      "merchantSupportEmail": {
        "type": "string",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "workflow": {
        "type": [
          "checkout",
          "dunning"
        ],
        "isOptional": true
      }
    },
    "namespace": "paymentsui"
  },
  "pageInteraction": {
    "name": "page-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "firstInteraction"
      ],
      "screen": [
        "public",
        "embedded"
      ],
      "type": [
        "form"
      ],
      "section": "string",
      "target": "string",
      "referrer": {
        "type": "string",
        "isOptional": true
      },
      "merchantSupportEmail": {
        "type": "string",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "workflow": {
        "type": [
          "checkout",
          "dunning"
        ],
        "isOptional": true
      }
    },
    "namespace": "paymentsui"
  },
  "paymentsCheckoutSubmission": {
    "name": "payments-checkout-submission",
    "class": "usage",
    "properties": {
      "processorType": {
        "type": "string"
      },
      "currencyCode": {
        "type": "string"
      },
      "numLineItemsOptionalSelected": {
        "type": "string",
        "isOptional": true
      },
      "numLineItemsOptional": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "successfulPaymentSubmission"
      ],
      "paymentSessionId": {
        "type": "string"
      },
      "screen": [
        "public",
        "embedded"
      ],
      "numLineItems": {
        "type": "number"
      },
      "numLineItemsEditable": {
        "type": "number"
      },
      "numLineItemsOpenAmount": {
        "type": "number"
      },
      "numLineItemsEdited": {
        "type": "number"
      },
      "numLineItemsEditedQuantity": {
        "type": "number"
      },
      "numLineItemsEditedOpenAmount": {
        "type": "number"
      },
      "numLineItemsWithTax": {
        "type": "number"
      },
      "numLineItemsScheduled": {
        "type": "number"
      },
      "dealPipelineStageId": {
        "type": "number"
      },
      "numProductLineItems": {
        "type": "number"
      },
      "numCustomLineItems": {
        "type": "number"
      },
      "numOneTimeLineItems": {
        "type": "number"
      },
      "numRecurringLineItems": {
        "type": "number"
      },
      "billingFrequency": {
        "type": "array",
        "isOptional": true
      },
      "paymentMethod": {
        "type": [
          "ACH",
          "CARD",
          "SEPA",
          "BACS",
          "PADS"
        ],
        "isOptional": true
      },
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "walletType": {
        "type": [
          "google_pay",
          "apple_pay"
        ],
        "isOptional": true
      },
      "hasSuccessUrl": {
        "type": "boolean"
      },
      "collectShippingAddress": {
        "type": "boolean"
      },
      "isBillingSameAsShipping": {
        "type": "boolean"
      },
      "customFormFields": {
        "type": "array"
      },
      "sourceApp": {
        "type": "string"
      },
      "sourceId": {
        "type": "string"
      },
      "hasDescriptionHtml": {
        "type": "boolean"
      },
      "requiredCheckout": {
        "type": "boolean"
      },
      "discountsApplied": {
        "type": [
          "PERCENT",
          "FIXED"
        ],
        "isOptional": true
      },
      "totalDiscountsRate": {
        "type": "number",
        "isOptional": true
      },
      "salesTax": {
        "type": [
          "AUTO",
          "MANUAL",
          "NONE"
        ]
      },
      "oneTimeFeesAmount": {
        "type": "boolean"
      },
      "paymentScheduleEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "isPaymentMethodOnFileEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "referrer": {
        "type": "string",
        "isOptional": true
      },
      "merchantSupportEmail": {
        "type": "string",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "workflow": {
        "type": [
          "checkout",
          "dunning"
        ],
        "isOptional": true
      }
    },
    "namespace": "paymentsui"
  }
};