import enviro from 'enviro';
import { getQueryParams, getHsVars, getIframeLayout } from './queryParams';
// https://git.hubteam.com/HubSpot/IdentityBase/blob/master/src/main/protobuf/contacts.proto#L347
const PAYMENT_FORM_TYPE = 12;

// https://git.hubteam.com/HubSpot/hubspot-cookie-banner/blob/master/cookie-banner/static/js/constants/privacyPolicy.js#L11
const NO_COOKIE_PRIVACY_POLICY = 2;
export function getCookieValueByName(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = cookie.substring(name.length + 1);
        break;
      }
    }
  }
  return cookieValue;
}
export function getUtk() {
  const queryParams = getQueryParams();
  if (!queryParams || !getIframeLayout() || !queryParams.parentHubspotUtk) {
    return getCookieValueByName('hubspotutk');
  }
  return queryParams.parentHubspotUtk;
}

/**
 * https://yoast.com/rel-canonical/
 * Canonical URLs tells search engines that certain similar URLs are actually
 * the same. The canonical URL is also how AnalyticsJS determines the URL of page
 * when sending the pageView event.
 *
 * The canonical URL of a buy-now link should be the original buy-now URL in
 * standalone mode, not the unique checkout session URL. This allows us to
 * associate pageView events with buy-now links, not buy-now sessions.
 *
 * In embed mode, the canonical URL must be set to the parent page URL.
 * Analytics buckets traffic under the canonical URL
 * Reports -> Analytic tools -> Traffic analytics -> Sources buckets under canonical URL
 * Reports -> Analytic tools -> Traffic analytics -> Pages buckets under pageId, then under canonical URL is pageId not available
 */
export function addCanonicalUrl(checkoutSession, embedded) {
  const linkId = 'canonical-url';
  if (document.getElementById(linkId)) {
    return;
  }
  const queryParams = getQueryParams();
  const parentPageUrl = queryParams && queryParams.parentPageUrl;
  const canonicalUrl = embedded && parentPageUrl ? parentPageUrl : checkoutSession.get('sourceUrl');
  const canonicalUrlLink = document.createElement('link');
  canonicalUrlLink.id = linkId;
  canonicalUrlLink.rel = 'canonical';
  canonicalUrlLink.href = canonicalUrl;
  document.body.appendChild(canonicalUrlLink);
}

/**
 * Returns ScriptLoader URL that will initiate the HubSpot tracking code (AnalyticsJS)
 * https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code. The
 * ?analyticsCommon query param is used to disable the automatic pageView
 * tracking (we don't want to track pageViews in embed mode b/c the parent page
 * should be in charge of tracking pageViews).
 *
 * @param {Number} portalId - The portalId the buy-now link belongs to
 * @param {Boolean} embedded - Whether the buy-now link is rendering in embed mode
 * @return {String} - The tracking code URL to add as a script to the page
 */
export function getAnalyticsScriptSource(portalId, embedded) {
  const qaPostfix = enviro.isQa() ? 'qa' : '';
  const queryParam = `?disableCollectedForms=true${embedded ? '&analyticsCommon=true' : ''}`;
  return `//js.hs-scripts${qaPostfix}.com/${portalId}.js${queryParam}`;
}

/**
 * The HubSpot tracking code (ScriptLoader) adds not only the analyticsJS script,
 * but also a number of other scripts that can trigger popups.
 *
 * Before adding the HubSpot tracking code <script /> to the page, we need to
 * disable certain popups that ScriptLoader tries to render.
 *
 * This follows the MeetingsUI example for disabling popups
 * https://git.hubteam.com/HubSpot/MeetingsUI/blob/a5b5c22b9168cd8ad328ded0a630778a4f5ec62c/MeetingsPublic/static/js/app.js#L151
 *
 * @param {Boolean} embedded - Whether the buy-now link is rendering in embed mode
 */

export function disableHsPopups(embedded) {
  window.disabledHsPopups = ['LEADFLOW', 'FEEDBACK', 'CALLS_TO_ACTION'];
  if (embedded) {
    window.disabledHsPopups.push('PRIVACY', 'ADS', 'LIVE_CHAT');
  }
}

/**
 * When rendering in embed mode, we need to associate the formView analytic event
 * with the parent CMS page instead of the iframed checkout page. Attaches a
 * number of callbacks BEFORE the formView event is sent so that the event is
 * associated properly with the parent page.
 *
 * If rendering in embed mode and parent page is not a CMS page, all these
 * callbacks will fallback to setting `null`.
 *
 * Parent CMS page data is passed via query params when checkout app is rendering
 * in embed mode (using the window.hsVars object available on the parent CMS page)
 *
 * Follow Meetings model https://git.hubteam.com/HubSpot/MeetingsUI/blob/master/MeetingsLib/static/js/utils/analytics.js#L43
 *
 * @param {Number} portalId - The portalId associated with the buy-now session
 */
function attachEmbedSpecificFormViewAnalayticCallbacks(portalId) {
  const {
    page_id,
    analytics_page_type,
    folder_id,
    content_group_id
  } = getHsVars();
  const setPortalId = ['setPortalId', portalId];

  // Hard to conform to cookie laws while in an iframe (eg. allowing users to
  // remove cookies they once consented to). Just disable cookies entirely.
  const setCookiePolicy = ['setCookiePolicy', [{
    enabled: true,
    privacyPolicy: NO_COOKIE_PRIVACY_POLICY,
    privacyDefault: true
  }]];
  const setPageId = ['setPageId', page_id || null];
  const setContentType = ['setContentType', analytics_page_type || null];
  const setContentMetadata = ['setContentMetadata', {
    contentPageId: page_id || null,
    contentFolderId: folder_id || null,
    contentGroupId: content_group_id || null
  }];
  window._hsq.push(setPortalId);
  window._hsq.push(setCookiePolicy);
  window._hsq.push(setPageId);
  window._hsq.push(setContentType);
  window._hsq.push(setContentMetadata);
}

/**
 * Sends a formView analytic event using the formId associated with the buy-now
 * link. If app rendering in embed mode, associate the formView analytic event
 * with the parent CMS page
 *
 * @param {Record} checkoutSession - The buy-now session
 * @param {Boolean} embedded - Whether the buy-now link is rendering in embed mode
 */
export function trackFormViewAnalyticEvent(checkoutSession, embedded) {
  window._hsq = window._hsq || [];
  const formGuid = checkoutSession.get('formGuid');
  const portalId = checkoutSession.get('portalId');
  if (!formGuid) {
    return;
  }
  if (embedded) {
    attachEmbedSpecificFormViewAnalayticCallbacks(portalId);
  }
  const attachFormGuid = ['trackFormView', formGuid, {
    formType: PAYMENT_FORM_TYPE
  }];
  window._hsq.push(attachFormGuid);
}

/**
 * Adds a new <script /> to the page that will load the hubspot tracking code
 * https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code
 *
 * When rendering in standalone mode, the tracking code script will immediately
 * send a pageView event
 *
 * When rendering in embed mode (`analyticsCommon=true`), the pageView is not
 * sent automatically on mount of the script. This is intentional because
 * embed mode should NOT send pageView events (the parent page of the embedded
 * buy-now link should be in charge of sending pageView events)
 *
 * Before adding this script, popups should be properly disabled, and a canonical
 * URL should be set on the page
 *
 * @param {Record} checkoutSession - The buy-now session
 * @param {Boolean} embedded - Whether the buy-now link is rendering in embed mode
 */
export function addHsTrackingCode(checkoutSession, embedded) {
  window._hsq = window._hsq || [];
  addCanonicalUrl(checkoutSession, embedded);
  disableHsPopups(embedded);
  const queryParams = getQueryParams();
  if (embedded && queryParams.parentHubspotUtk) {
    // set __hsUserToken as utk passed in from parent page so
    // analytics tracker will init with consistent utk across iframe and parent
    // otherwise, we have form submission and view with incorrect source
    // https://git.hubteam.com/HubSpot/MeetingsUI/blob/a5b5c22b9168cd8ad328ded0a630778a4f5ec62c/MeetingsPublic/static/js/app.js#L163
    window.__hsUserToken = queryParams.parentHubspotUtk;
  }
  const scriptId = 'hs-script-loader';
  if (document.getElementById(scriptId)) {
    return;
  }
  const hsAnalyticsScript = document.createElement('script');
  hsAnalyticsScript.async = true;
  hsAnalyticsScript.id = scriptId;
  hsAnalyticsScript.src = getAnalyticsScriptSource(checkoutSession.get('portalId'), embedded);
  document.body.appendChild(hsAnalyticsScript);
}