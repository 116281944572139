import quickFetch from 'quick-fetch';
export const getQuickFetchOrMakeRequest = (name, makeRequest, validator) => {
  const earlyMetaRequest = quickFetch.getRequestStateByName(name);
  const validated = validator && validator instanceof Function ? validator(earlyMetaRequest) : true;
  if (earlyMetaRequest && validated) {
    return new Promise((resolve, reject) => {
      earlyMetaRequest.whenFinished(result => {
        resolve(result);
        quickFetch.removeEarlyRequest(name);
      });
      earlyMetaRequest.onError(() => {
        makeRequest().then(resolve, reject).catch(reject);
        quickFetch.removeEarlyRequest(name);
      });
    });
  } else {
    return makeRequest();
  }
};