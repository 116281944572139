import noAuthClient from 'hub-http/clients/noAuthApiClient';
import { catchAndRethrowNetworkError
// @ts-expect-error Untyped module
} from 'crm_data/bCommerce/monitoring/errorUtils';
export const fetchValidationErrors = ({
  checkoutBuyerOverrides,
  paymentMethod,
  email,
  billingInfo,
  shippingInfo
}) => {
  const paymentSessionId = window.location.pathname.split('/purchase/')[1];
  return noAuthClient.post(`payments-checkout/v1/checkout-sessions/${paymentSessionId}/validate`, {
    data: {
      checkoutBuyerOverrides,
      paymentMethod,
      email,
      billingInfo,
      shippingInfo
    }
  }).catch(catchAndRethrowNetworkError);
};