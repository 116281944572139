// @ts-expect-error Untyped module
import { ravenDataCallback } from 'crm_data/bCommerce/monitoring/errorUtils';
const API_REGEX = /(\/public\/)(.+)(\/payments-sessions)/g;
const FINGERPRINT_KEY = '<portal_id_or_key>';

// ex: session-templates/public/mwSGt_M4/payments-sessions -> session-templates/public/<portal_id_or_key>/payments-sessions
export const replacePublicId = inputStr => {
  return inputStr.replace(API_REGEX, `$1${FINGERPRINT_KEY}$3`);
};

// This util exists because ravenDataCallback supports this sort of logic for replacing tokens, but it often fails on Payment Links IDs,
// since they (unlike portal IDs) can contain special characters. This logic adds a preliminary token replacement
// step specific to payment link ids before we call the CRM error utility
export const checkoutRavenDataCallback = (data, original) => {
  if (data.message && data.fingerprint && data.fingerprint[2]) {
    data.fingerprint[2] = replacePublicId(data.fingerprint[2]);
    data.message = replacePublicId(data.message);
  }
  ravenDataCallback(data, original);
};