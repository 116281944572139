import noAuthClient from 'hub-http/clients/noAuthApiClient';
import getIn from 'transmute/getIn';
// @ts-expect-error Untyped module
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';
import { getQuickFetchOrMakeRequest } from '../earlyRequester/getQuickFetchOrMakeRequest';
export const makeRequest = countryCode => () => {
  return noAuthClient.get(`/address-meta/v1/countries/${countryCode}/metadata`);
};
const validator = countryCode => earlyMetaRequest => {
  const earlyRequestCountryCode = getIn(['data', 'country', 'code'], earlyMetaRequest);
  return earlyRequestCountryCode === countryCode;
};
export const fetchCountryMetadata = ({
  countryCode
}) => {
  return getQuickFetchOrMakeRequest('countryCode', makeRequest(countryCode), validator(countryCode)).catch(catchAndRethrowNetworkError);
};