'use es6';

// via https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger#Polyfill
const isInteger = val => {
  return typeof val === 'number' && isFinite(val) && Math.floor(val) === val;
};

// via https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/sign#Polyfill
const sign = x => (x > 0) - (x < 0) || +x;
const range = (start = 0, stop, step = 1) => {
  /* eslint-disable no-param-reassign */
  if (!stop) {
    stop = start;
    start = 0;
  }
  if (![start, stop, step].every(isInteger)) {
    throw new Error('Invalid array length');
  }
  if (step === 0) step = 1; // WHAT. Underscore does this so we have to for compatibility. WHAT.
  if (sign(stop - start) !== sign(step)) return [];
  const length = Math.abs((start - stop) / step);
  return Array.apply(null, Array(length)).map((_, i) => start + step * i);
};
export default range;