'use es6';

import ActionTypes from '../actions/ActionTypes';
const DEFAULT_STATE = {};
export default function Notifications(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case ActionTypes.ADD_NOTIFICATION:
      return Object.assign({}, state, {
        [action.payload.id]: action.payload
      });
    case ActionTypes.REMOVE_NOTIFICATION:
      {
        if (state[action.payload.id] == null) return state;
        const nextState = Object.assign({}, state);
        delete nextState[action.payload.id];
        return nextState;
      }
    case ActionTypes.REMOVE_ALL_NOTIFICATIONS:
      {
        // Sets the state to an empty object which will remove all notifications
        return DEFAULT_STATE;
      }
    default:
      return state;
  }
}