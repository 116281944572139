import { useDispatch, useSelector } from 'react-redux';
import { getBuyerOverridesSelections } from '../../state//BuyerOverrides/selectors';
import { isOptional } from '../../types/utils/typeguards';
import noop from 'hs-lodash/noop';
import BuyerOverridesActionTypes from '../../state/BuyerOverrides/actionTypes';
import usePageInteractionTracking from '../usePageInteractionTracking';
import { INTERACTION_SECTIONS, INTERACTION_TARGETS } from '../../constants/PageInteractionTracking';
export const useOptionalStates = lineItems => {
  const buyerOverridesSelections = useSelector(getBuyerOverridesSelections);
  const dispatch = useDispatch();
  const {
    trackFirstFormFieldInteraction
  } = usePageInteractionTracking();
  return {
    selectedItems: lineItems.map(l =>
    // buyerOverridesSelections starts as a blank object, so
    // selectedItems[number] will be false
    // in both never-selected and de-selected cases
    isOptional(l) ? buyerOverridesSelections[l.id] === true : null),
    selectionToggles: lineItems.map(l => isOptional(l) ? () => {
      trackFirstFormFieldInteraction({
        section: INTERACTION_SECTIONS.orderSummaryLineItem,
        target: INTERACTION_TARGETS.optionalItemAddButton
      });
      dispatch({
        type: BuyerOverridesActionTypes.SELECTION_TOGGLED,
        data: l.customConfiguration.hasEditablePrice ? {
          id: l.id,
          price: l.price
        } : l.customConfiguration.hasEditableQuantity ? {
          id: l.id,
          quantity: l.quantity
        } : {
          id: l.id
        }
      });
    } : noop)
  };
};