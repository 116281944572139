import { FALLBACK_FONT_STACK as SYS_FONT_STACK, PRIORITY_WEB_FONT, WEB_FONT_REGULAR_WEIGHT, WEB_FONT_MEDIUM_WEIGHT, WEB_FONT_DEMI_BOLD_WEIGHT, WEB_FONT_BOLD_WEIGHT, ICON_FONT_FAMILY } from 'HubStyleTokens/misc';
import { domReady } from '../listeners/domReady';
let forceSkip = false;
let fontsHaveLoaded = false;
let iconFontsHaveLoaded = false;

/**
 * Hook for tests to skip font measurement. Must be called before `DOMContentLoaded` fires.
 */
export const forceSkipFontMeasurement = () => {
  forceSkip = true;
};

/**
 * Creates a measure element with the specified content, `font-family`, and `font-weight`, and
 * appends it to `document.body`.
 *
 * @param {string} content
 * @param {string} fontFamily
 * @param {string} fontWeight
 */
export const createMeasureEl = (content, fontFamily, fontWeight) => {
  const measureEl = document.createElement('div');
  measureEl.setAttribute('aria-hidden', 'true'); // http://go/jira/CG-13204
  measureEl.textContent = content;
  measureEl.style.position = 'absolute';
  measureEl.style.top = '-999px';
  measureEl.style.fontFamily = fontFamily;
  measureEl.style.fontWeight = fontWeight;
  document.body.appendChild(measureEl);
  return measureEl;
};

/**
 * Compares the size of a set of elements that use web fonts and a corresponding set of elements
 * that use system fonts. If the elements have different sizes (indicating that the web font has
 * loaded), the mismatched elements are removed from both sets.
 *
 * @param {Array} webFontMeasureEls
 * @param {Array} sysFontMeasureEls
 */
const compareMeasureEls = (webFontMeasureEls, sysFontMeasureEls) => {
  for (let i = 0; i < webFontMeasureEls.length; i++) {
    // Use getBoundingClientRect().width as it returns floats. offsetWidth would
    // round floats to the nearest integer, causing comparisons between some
    // fonts to fail (system bold vs Lexand Bold would both get rounded to 73, even
    // though the floats were different values)
    const webFontTextWidth = webFontMeasureEls[i].getBoundingClientRect().width;
    const sysFontTextWidth = sysFontMeasureEls[i].getBoundingClientRect().width;
    if (webFontTextWidth !== sysFontTextWidth) {
      document.body.removeChild(webFontMeasureEls[i]);
      document.body.removeChild(sysFontMeasureEls[i]);
      webFontMeasureEls.splice(i, 1);
      sysFontMeasureEls.splice(i, 1);
    }
  }
};

/**
 * A promise that resolves when all variants of our base font have been loaded.
 */
export const fontsLoadedPromise = new Promise(resolve => {
  domReady(() => {
    if (forceSkip) {
      resolve();
      return;
    }

    // For each font weight, create a test element with our webfont and another with the fallback.
    const testText = 'BESbswy';
    const WEB_FONT_STACK = `"${PRIORITY_WEB_FONT}", ${SYS_FONT_STACK}`;
    const webFontMeasureEls = [];
    const sysFontMeasureEls = [];
    [WEB_FONT_REGULAR_WEIGHT, WEB_FONT_MEDIUM_WEIGHT, WEB_FONT_DEMI_BOLD_WEIGHT, WEB_FONT_BOLD_WEIGHT].forEach(weight => {
      webFontMeasureEls.push(createMeasureEl(testText, WEB_FONT_STACK, weight));
      sysFontMeasureEls.push(createMeasureEl(testText, SYS_FONT_STACK, weight));
    });
    const poll = () => {
      compareMeasureEls(webFontMeasureEls, sysFontMeasureEls);
      if (webFontMeasureEls.length === 0) {
        fontsHaveLoaded = true;
        resolve();
      } else {
        setTimeout(poll, 50);
      }
    };
    poll();
  });
});

/**
 * A function that corresponds to whether `fontsLoadedPromise` has resolved.
 * @returns {boolean}
 */
export const fontsLoaded = () => fontsHaveLoaded;

/**
 * A promise that resolves when all variants of our icon font have been loaded.
 */
export const iconFontsLoadedPromise = new Promise(resolve => {
  domReady(() => {
    if (forceSkip) {
      resolve();
      return;
    }

    // For each font weight, create a test element with our webfont and another with the fallback.
    const testText = 'add';
    const WEB_FONT_STACK = `"${ICON_FONT_FAMILY}", ${SYS_FONT_STACK}`;
    const webFontMeasureEls = [];
    const sysFontMeasureEls = [];
    ['normal',
    // spacesword-low
    'bold' // spacesword-high
    ].forEach(weight => {
      webFontMeasureEls.push(createMeasureEl(testText, WEB_FONT_STACK, weight));
      sysFontMeasureEls.push(createMeasureEl(testText, SYS_FONT_STACK, weight));
    });
    const poll = () => {
      compareMeasureEls(webFontMeasureEls, sysFontMeasureEls);
      if (webFontMeasureEls.length === 0) {
        iconFontsHaveLoaded = true;
        resolve();
      } else {
        setTimeout(poll, 50);
      }
    };
    poll();
  });
});

/**
 * A function that corresponds to whether `iconFontsLoadedPromise` has resolved.
 * @returns {boolean}
 */
export const iconFontsLoaded = () => iconFontsHaveLoaded;